import { graphql } from "gatsby";
import React from "react";
import convertId from "../../utilities/convert-id";
import Content from "../utilities/content";
import Jotform from "../utilities/jotform";

const ContactUsForm = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section section-contact-us-form ${
        data.backgroundColor === "white" ? "bg-white" : "bg-gray"
      }`}
    >
      <div className="container">
        <div className="grid-contact-form">
          <div>
            <Content className="content-main content-contact-us">
              {data.content}
            </Content>
          </div>
          <div>
            {data.form === "contact" ? (
              <Jotform>220855933156056</Jotform>
            ) : (
              <Jotform>220856129911053</Jotform>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContactUsForm on WpPage_Sections_Sections_ContactUsForm {
    fieldGroupName
    hideSection
    name
    backgroundColor
    form
    content
  }
`;

export default ContactUsForm;
