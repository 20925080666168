import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";
const Team = ({ data }) => {

  return (
    <section
      id={convertId(data.name)}
      className={`section section-team accordion-section accordion-small ${
        data.backgroundColor === "white" ? "bg-white" : "bg-gray"
      }`}
    >
      <div className="container">
        <div className="grid grid-team">
          {data.title && (
            <div className="team-top g-col-1 g-col-sm-2 g-col-lg-3">
              <Content className="content-main content-team-title">
                {data.title}
              </Content>
            </div>
          )}
          {data.team.map((member, index) => (
            <div key={member.name}>
              <div className="member">
                <div className="member-image-outside">
                  <div className="member-image">
                    <div className="member-image-inside">
                      <CmsImage image={member.image} />
                    </div>
                  </div>
                </div>
                <div className="member-name">{member.name}</div>
                {member.jobTitle && (
                  <div className="member-job">{member.jobTitle}</div>
                )}
                {(member.phone || member.email) && (
                  <div className="member-contact">
                    {member.phone && (
                      <a
                        className="d-block member-phone"
                        href={`tel:${member.phone}`}
                      >
                        {member.phone}
                      </a>
                    )}
                    {member.email && (
                      <a
                        className="d-block member-email"
                        href={`mailto:${member.email}`}
                      >
                        {member.email}
                      </a>
                    )}
                  </div>
                )}
                {member.content && (
                    <div className="accordion accordion-flush" id={`memberAcord-` + [index]}>
                        <div className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#member-` + [index]} aria-expanded="false" aria-controls={`member-` + [index]}>
                            <div className="content-main content-accordion-title">
                              <p>{ member.buttonText }</p>
                            </div>
                          </button>
                        </div>
                        <div id={`member-` + [index]} className="accordion-collapse collapse" data-bs-parent={`memberAcord-` + [index]}>
                          <div className="accordion-body">
                            <Content className="content-main content-member">
                              { member.content }
                            </Content>
                          </div>
                        </div>
                    </div>
                  /*<Content className="content-main content-member">
                    {member.content}
                  </Content>*/
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Team on WpPage_Sections_Sections_Team {
    fieldGroupName
    name
    backgroundColor
    title
    team {
      name
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 536, height: 518)
          }
          extension
          publicURL
        }
      }
      jobTitle
      phone
      email
      buttonText
      content
    }
  }
`;

export default Team;
