import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery-react17";
import Carousel, { Modal, ModalGateway } from "react-images";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";

const ImageGallery = ({ data }) => {
  const photos =
    data.galleries && data.galleries.length !== 0 && data.galleries[0].localFile
      ? data.galleries.map((i) => {
          if (i.localFile) {
            return {
              src: i.localFile.publicURL,
              width: 3,
              height: 2,
              className: "image-padding",
            };
          }
          return null;
        })
      : null;

  useEffect(() => {});
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <section id={convertId(data.name)} className="image-gallery">
      <div className="sz container mx-auto">
        {photos && <Gallery photos={photos} onClick={openLightbox} />}
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ImageGallery on WpPage_Sections_Sections_ImageGallery {
    fieldGroupName
    name
    galleries {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default ImageGallery;
