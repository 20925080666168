import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import ContactFormUtil from "../utilities/contact-form";

const ContactForm = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="section">
      <div className="container mx-auto">
        <ContactFormUtil />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContactForm on WpPage_Sections_Sections_ContactForm {
    fieldGroupName
    name
  }
`;

export default ContactForm;
