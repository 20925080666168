import React from "react";

const SvgHockeyField = ({
  className,
  keyCount,
  color,
  classRelation,
  ...other
}) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .${classRelation} >
            .swiper-pagination-bullet:nth-child(${keyCount + 1}) {
            background-color: ${color} !important;
            }
        `,
        }}
      />
      <svg
        className={`${className ? className : ""}`}
        width="657"
        height="190"
        viewBox="0 0 657 190"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.62231 188.759L156.247 1.24707H500.007L654.426 188.759H2.62231Z"
          fill={color}
        />
        <path
          d="M2.62231 188.759L156.247 1.24707H500.007L654.426 188.759H2.62231Z"
          stroke="white"
          stroke-width="2.2"
          stroke-miterlimit="10"
        />
        <path
          d="M100.377 161.663L196.736 11.6233H453.223L547.85 161.663H100.377Z"
          stroke="white"
        />
        <path
          d="M326.335 162.132V10.6421"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M261.05 11.4778L215.493 161.189"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M390.825 11.4778L436.383 161.189"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M469.566 140.607L478.227 161.189"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M415.196 11.4778L418.468 19.2583"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M182.839 140.607L174.178 161.189"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M237.209 11.4778L233.937 19.2583"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M324.258 66.9546H328.5"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M183.713 31.1349C183.713 31.1349 229.799 29.8688 213.384 67.518C196.968 105.167 147.69 116.442 130.208 116.025"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M191.13 23.7468H191.523"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M203.68 25.0952C219.374 28.0834 242.622 37.8518 229.077 69.3032C210.016 113.575 157.169 129.642 131.459 131.687"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1.02 15.33"
        />
        <path
          d="M125.316 131.909H124.916"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M183.713 66.676L183.36 67.7966"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1 15"
        />
        <path
          d="M141.331 98.0452H143.456"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M150.072 84.2439H152.197"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M171.796 50.3679H173.913"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path d="M177.883 40.9602H180" stroke="white" stroke-miterlimit="10" />
        <path
          d="M465.958 31.1349C465.958 31.1349 419.871 29.8688 436.287 67.518C452.702 105.167 501.972 116.442 519.454 116.025"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M458.54 23.7468H458.147"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M446.006 25.0952C430.296 28.0834 407.048 37.8518 420.593 69.3032C439.655 113.549 492.517 129.642 518.179 131.687"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1.02 15.33"
        />
        <path
          d="M524.354 131.909H524.755"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M465.958 66.676L466.311 67.7966"
          stroke="white"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="1 15"
        />
        <path
          d="M508.331 98.0452H506.214"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M499.59 84.2439H497.473"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M477.874 50.3679H475.757"
          stroke="white"
          stroke-miterlimit="10"
        />
        <path
          d="M471.788 40.9602H469.663"
          stroke="white"
          stroke-miterlimit="10"
        />
      </svg>
    </>
  );
};

export default SvgHockeyField;
