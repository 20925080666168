import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql, useStaticQuery } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import Map from "../utilities/map";

const FindRepsInMap = ({ data }) => {
  const {
    wp: {
      themeSettings: {
        themeSettings: { mapArrowLink, mapSalesRepresentatives: mapData },
      },
    },
  } = useStaticQuery(
    graphql`
      query SalesRepsMapData {
        wp {
          themeSettings {
            themeSettings {
              mapArrowLink {
                target
                title
                url
              }
              mapSalesRepresentatives {
                name
                state
              }
            }
          }
        }
      }
    `
  );

  return (
    <section
      id={convertId(data.name)}
      className="section section-find-sales-rep section-map clipped-small-top-double-bottom"
    >
      <div className="container sz">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-last mb-5 mb-lg-0 map-col-parent">
            <Map
              data={mapData}
              mapArrowLink={mapArrowLink}
              mapType="sales-reps"
            />
          </div>
          <div className="col-lg-6">
            <div className="mb-4 ">
              <Content className="content-find-reps-map">
                {data.content}
              </Content>
            </div>

            {data.buttonLink && (
              <CmsLink
                className="btn btn-primary  me-n4 btn-arrow"
                link={data.buttonLink}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FindRepsInMap on WpPage_Sections_Sections_FindRepsInMap {
    name
    content
    buttonLink {
      target
      title
      url
    }
    fieldGroupName
  }
`;

export default FindRepsInMap;
