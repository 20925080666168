import React from "react";
import { graphql } from "gatsby";
import TermlyScript from "../utilities/termly-script";
import convertId from "../../utilities/convert-id";

const TermlySection = ({ data }) => {
    return (
        <section
            id={convertId(data.name)}
            className="termly-section"
        >
            <TermlyScript id={data.dataId} />
        </section>
    );
};

export const query = graphql`
  fragment TermlySection on WpPage_Sections_Sections_TermlySection {
    fieldGroupName
    name
    dataId
  }
  fragment ReusableTermlySection on WpReusableSection_Sections_Sections_TermlySection {
    fieldGroupName
    name
    dataId
  }
`;

export default TermlySection;
