import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const CenteredContent = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="centered-content">
      <div className="container mx-auto sz">
        <Content className="">{data.content}</Content>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment CenteredContent on WpPage_Sections_Sections_CenteredContent {
    fieldGroupName
    name
    content
  }
`;

export default CenteredContent;
