import React, { useEffect } from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../../components/utilities/content";
import CmsLink from "../utilities/cms-link";
import SvgBaseballField from "../utilities/svg-baseball-field";
import SvgFootballField from "../utilities/svg-football-field";
import useMediaQuery from "../utilities/useMediaQuery";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { EffectFade, Navigation, Pagination } from "swiper";
import SvgSoccerField from "../utilities/svg-soccer-field";
import SvgHockeyField from "../utilities/svg-hockey-field";

const BuildYourOwnField = ({ data }) => {
  const SwiperResponsive = (matches) => {
    return {
      className: matches ? "swiper-features-horizontal" : "swiper-features",
      direction: matches ? "horizontal" : "vertical",
      loop: true,
      autoplay: false,
      slidesPerView: 1,
      spaceBetween: 100,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation],
    };
  };

  const matches = useMediaQuery();

  let swiperAttributes = SwiperResponsive(matches);

  let footballColorsAttributes = {
    className: "swiper-field",
    loop: true,
    autoplay: false,
    effect: "fade",
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    modules: [EffectFade, Pagination],
  };

  let baseballColorsAttributes = {
    className: "swiper-baseball",
    loop: true,
    autoplay: false,
    effect: "fade",
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    modules: [EffectFade, Pagination],
  };

  let soccerColorsAttributes = {
    className: "swiper-soccer",
    loop: true,
    autoplay: false,
    effect: "fade",
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    modules: [EffectFade, Pagination],
  };

  let hockeyColorsAttributes = {
    className: "swiper-hockey",
    loop: true,
    autoplay: false,
    effect: "fade",
    spaceBetween: 30,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    modules: [EffectFade, Pagination],
  };

  const handlePaginationHover = (e) => {
    e.currentTarget.classList.add("swiper-pagination-bullet-active");
    e.currentTarget.click();
  };

  useEffect(() => {
    document.querySelectorAll(".swiper-pagination-bullet").forEach((item) => {
      item.addEventListener("mouseenter", handlePaginationHover);
    });

    return () => {
      document.querySelectorAll(".swiper-pagination-bullet").forEach((item) => {
        item.removeEventListener("mouseenter", handlePaginationHover);
      });
    };
  });

  return (
    <>
      <section
        id={convertId(data.name)}
        className="section section-build-your-own-field  clipped-small-bottom"
      >
        <div className="container sz">
          <div className=" align-items-center">
            <div className="row align-items-center">
              <div className="col-lg-6 order-lg-last ">
                <Swiper {...swiperAttributes}>
                  <div className="swiper-buttons swiper-buttons-top">
                    <div className="container position-relative">
                      <div className="swiper-button-prev" />
                    </div>
                  </div>
                  <SwiperSlide key="keyforfield">
                    <Swiper {...footballColorsAttributes}>
                      {data.footballColors &&
                        data.footballColors.map((item, i) => (
                          <SwiperSlide key={i}>
                            <SvgFootballField
                              className="mx-auto img-fluid d-block"
                              keyCount={i}
                              color={item.fieldColor}
                              classRelation="football-pagination"
                            />
                          </SwiperSlide>
                        ))}
                      <div className="football-pagination swiper-pagination " />
                    </Swiper>
                  </SwiperSlide>
                  <SwiperSlide key="keyforbaseball">
                    <Swiper {...baseballColorsAttributes}>
                      {data.baseballColors &&
                        data.baseballColors.map((item, i) => (
                          <SwiperSlide key={i}>
                            <SvgBaseballField
                              className="mx-auto img-fluid d-block"
                              keyCount={i}
                              infieldColor={item.infieldColor}
                              outfieldColor={item.outfieldColor}
                              classRelation="baseball-pagination"
                            />
                          </SwiperSlide>
                        ))}
                      <div className="baseball-pagination swiper-pagination " />
                    </Swiper>
                  </SwiperSlide>
                  <SwiperSlide key="keyforsoccer">
                    <Swiper {...soccerColorsAttributes}>
                      {data.soccerColors &&
                        data.soccerColors.map((item, i) => (
                          <SwiperSlide key={i}>
                            <SvgSoccerField
                              className="mx-auto img-fluid d-block"
                              keyCount={i}
                              color={item.fieldColor}
                              classRelation="soccer-pagination"
                            />
                          </SwiperSlide>
                        ))}
                      <div className="soccer-pagination swiper-pagination " />
                    </Swiper>
                  </SwiperSlide>
                  <SwiperSlide key="keyforhockey">
                    <Swiper {...hockeyColorsAttributes}>
                      {data.hockeyColors &&
                        data.hockeyColors.map((item, i) => (
                          <SwiperSlide key={i}>
                            <SvgHockeyField
                              className="mx-auto img-fluid d-block"
                              keyCount={i}
                              color={item.fieldColor}
                              classRelation="hockey-pagination"
                            />
                          </SwiperSlide>
                        ))}
                      <div className="hockey-pagination swiper-pagination " />
                    </Swiper>
                  </SwiperSlide>
                  <div className="swiper-buttons swiper-buttons-bottom">
                    <div className="container position-relative">
                      <div className="swiper-button-next" />
                    </div>
                  </div>
                </Swiper>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 ">
                  <Content className="content-build-your-own-field">
                    {data.content}
                  </Content>
                  {data.buttonLink && (
                    <CmsLink
                      className="btn btn-primary me-n4 btn-arrow"
                      link={data.buttonLink}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment BuildYourOwnField on WpPage_Sections_Sections_BuildYourOwnField {
    fieldGroupName
    name
    content
    buttonLink {
      target
      title
      url
    }
    footballColors {
      fieldGroupName
      fieldColor
    }
    baseballColors {
      fieldGroupName
      outfieldColor
      infieldColor
    }
    soccerColors {
      fieldGroupName
      fieldColor
    }
    hockeyColors {
      fieldGroupName
      fieldColor
    }
  }
`;

export default BuildYourOwnField;
