import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const ProductMoreInfo = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section-more-info section clipped-last bg-light"
    >
      <div className="container sz">
        <div className="section-group">
          {data.title && (
            <Content className="more-info-title display-5 tk-rift text-uppercase text-primary mb-0">
              {data.title}
            </Content>
          )}
        </div>
        <div className="section-group">
          <div className="row">
            {data.video && (
              <div className="col-lg-8">
                <div className="content content-main video-image-title tk-industry text-secondary text-uppercase mb-4">
                  <div className="h5">{data.video.title}</div>
                </div>
                <div className="me-lg-5">
                  <div className="ratio ratio-16x9">
                    <iframe
                      src={data.video.url}
                      title="YouTube video"
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="col-lg-4">
              {data.imageTitle && (
                <Content className="video-image-title tk-industry text-secondary text-uppercase mb-4">
                  {data.imageTitle}
                </Content>
              )}
              {data.image && (
                <CmsImage className="img-fliud w-100" image={data.image} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ProductMoreInfo on WpPage_Sections_Sections_ProductMoreInfo {
    fieldGroupName
    imageTitle
    name
    title
    video {
      target
      title
      url
    }
    image {
      altText
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default ProductMoreInfo;
