import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const Accordion = ({ data }) => {
  const sectionId = convertId(data.name),
    accordionId = `accordion-${sectionId}`;

  return (
    <section
      id={sectionId}
      className={`section section-accordion ${
        data.backgroundColor === "white" ? "bg-white" : "bg-gray"
      } ${
        data.accordionColor === "green" ? "accordion-green" : "accordion-white"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            {data.introContent && (
              <Content className="content-main content-accordion-top">
                {data.introContent}
              </Content>
            )}
            <div className="accordion accordion-flush" id={accordionId}>
              {data.accordions.map((accordion) => {
                const accordionItemId = convertId(accordion.name),
                  accordionHeading = `${accordionId}-heading-${accordionItemId}`,
                  accordionContent = `${accordionId}-content-${accordionItemId}`;
                return (
                  <div className="accordion-item" key={accordionItemId}>
                    <div className="accordion-header" id={accordionHeading}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#" + accordionContent}
                        aria-expanded="false"
                        aria-controls={accordionContent}
                      >
                        <Content className="content-main content-accordion-title">
                          {accordion.title}
                        </Content>
                      </button>
                    </div>
                    <div
                      id={accordionContent}
                      className="accordion-collapse collapse"
                      aria-labelledby={accordionHeading}
                      data-bs-parent={"#" + accordionId}
                    >
                      <div className="accordion-body">
                        <Content className="content-main content-accordion-content">
                          {accordion.content}
                        </Content>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Accordion on WpPage_Sections_Sections_Accordion {
    fieldGroupName
    name
    backgroundColor
    accordionColor
    introContent
    accordions {
      name
      title
      content
    }
  }
`;

export default Accordion;
