import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
// import arrowSvg from "../../images/arrow.svg";
import arrowGreenSvg from "../../images/arrow-green.svg";
import Content from "../utilities/content";
import Link from "../utilities/link";
import CmsLink from "../utilities/cms-link";
import CmsImage from "../utilities/cms-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const GearUpAndEducation = ({ data }) => {
  let swiperAttributes = {
    className: "swiper-gears mb-4",
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    modules: [Navigation],
  };

  return (
    <section
      id={convertId(data.name)}
      className="section section-gears-tools clipped-double"
    >
      <div className="container">
        <div className="row align-items-top">
          <div className="col-lg-6 mb-5 mb-lg-0 gear-wrapper">
            <div className="pe-lg-5">
              <Content className="content-title-bg-image">
                {data.gearUpContent}
              </Content>
              <Swiper {...swiperAttributes}>
                {data.gearUpProducts &&
                  data.gearUpProducts.map((product, key) => (
                    <SwiperSlide key={key}>
                      {product.image && (
                        <CmsImage
                          className="img-fluid mx-auto d-flex w-70"
                          image={product.image}
                        />
                      )}
                    </SwiperSlide>
                  ))}
                <div className="swiper-button-prev" />
                <div className="swiper-button-next" />
              </Swiper>
              <div className="text-center">
                {data.gearUpLink && (
                  <CmsLink
                    className="btn btn-primary  me-n4 btn-arrow"
                    link={data.gearUpLink}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 education-wrapper">
            <div className="ps-lg-5">
              <h2 className="text-uppercase display-4 tk-rift text-primary text-center mb-5">
                <Content className="content-title-bg-image">
                  {data.educationalContent}
                </Content>
              </h2>
              {data.educationalVideos &&
                data.educationalVideos.map((video, key) => (
                  <div
                    className="d-flex tool-box mb-5 education-tools-content"
                    key={key}
                  >
                    <iframe
                      src={video.videos}
                      title="YouTube video"
                      allowFullScreen
                    />
                    <div className="flex-grow-1 ms-3">
                      <Content className="education-content-video">
                        {video.content}
                      </Content>
                      {video.link && (
                        <CmsLink
                          className="btn btn-link p-0 text-uppercase text-decoration-none fw-bold"
                          link={video.link}
                        >
                          {video.link.title}
                          <CmsImage image={arrowGreenSvg} />
                        </CmsLink>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment GearUpAndEducation on WpPage_Sections_Sections_GearUpAndEducation {
    fieldGroupName
    gearUpContent
    gearUpLink {
      title
      target
      url
    }
    gearUpProducts {
      fieldGroupName
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    name
    educationalContent
    educationalVideos {
      content
      fieldGroupName
      videos
      link {
        target
        title
        url
      }
    }
  }
`;
export default GearUpAndEducation;
