import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import CmsImage from "../utilities/cms-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";

const ProductSpotlight = ({ data }) => {
  let swiperAttributes = {
    className: "swiper-spotlight",
    loop: true,
    slidesPerView: 1,
    spaceBetween: 100,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    modules: [Navigation, Autoplay],
    autoplay: {
      delay: 7500,
    },
  };

  return (
    <section
      id={convertId(data.name)}
      className="section section-product-spotlight clipped-small-top mobile-spotlight"
    >
      <img
        className="polygon-bg polygon-bg-top-left polygon-bg-desktop"
        src="/images/polygon-product-spotlight-top-left.svg"
      />
      <img
        className="polygon-bg polygon-bg-top-left polygon-bg-mobile"
        src="/images/polygon-product-spotlight-top-left-mobile.svg"
      />
      <Swiper {...swiperAttributes}>
        {data.spotlights &&
          data.spotlights.map((product, key) => (
            <SwiperSlide key={key}>
              <div className="container sz">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    {product.image && (
                      <CmsImage
                        className="img-fluid mx-auto d-block img-clipped mb-4 mb-lg-0"
                        image={product.image}
                      />
                    )}
                  </div>
                  <div className="col-lg-6 order-lg-first">
                    <Content className="mb-4 content-product-spotlight">
                      {product.content}
                    </Content>
                    {product.buttonLink && (
                      <CmsLink
                        className="btn btn-primary me-n4 btn-arrow"
                        link={product.buttonLink}
                      />
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        <div className="swiper-button-prev ps-swiper-button">
          <img src="/images/product-spotlight-arrow.svg" alt="Left Arrow" />
        </div>
        <div className="swiper-button-next ps-swiper-button">
          <img src="/images/product-spotlight-arrow.svg" alt="Right Arrow" />
        </div>
      </Swiper>
      <img
        className="polygon-bg polygon-bg-bottom-right polygon-bg-desktop"
        src="/images/polygon-product-spotlight-bottom-right.svg"
      />
      <img
        className="polygon-bg polygon-bg-bottom-right polygon-bg-mobile"
        src="/images/polygon-product-spotlight-bottom-right-mobile.svg"
      />
    </section>
  );
};

export const query = graphql`
  fragment ProductSpotlight on WpPage_Sections_Sections_ProductSpotlight {
    fieldGroupName
    name
    spotlights {
      buttonLink {
        target
        title
        url
      }
      content
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ProductSpotlight;
