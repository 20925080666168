import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const Tabs = ({ data }) => {
  const sectionId = convertId(data.name);
  return (
    <section
      id={sectionId}
      className={`section section-tabs clip-top ${
        data.backgroundColor === "white" ? "bg-white" : "bg-gray"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul
              className="nav nav-tabs tabs-tabs"
              id={sectionId + "-tabs"}
              role="tablist"
            >
              {data.tabs.map((tab, index) => {
                const tabId = convertId(tab.name);
                return (
                  <li className="nav-item" role="presentation" key={tabId}>
                    <button
                      className={`nav-link tab-title ${
                        index === 0 ? "active" : ""
                      }`}
                      id={sectionId + "-" + tabId + "-tab"}
                      data-bs-toggle="tab"
                      data-bs-target={"#" + sectionId + "-" + tabId}
                      type="button"
                      role="tab"
                      aria-controls={sectionId + "-" + tabId}
                      aria-selected={index === 0 ? "true" : "false"}
                    >
                      <Content className="content-main content-tab-title">
                        {tab.title}
                      </Content>
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content" id="myTabContent">
              {data.tabs.map((tab, index) => {
                const tabId = convertId(tab.name);
                return (
                  <div
                    className={`tab-pane fade ${
                      index === 0 ? "show active" : ""
                    }`}
                    id={sectionId + "-" + tabId}
                    role="tabpanel"
                    aria-labelledby={sectionId + "-" + tabId + "-tab"}
                    key={tabId}
                  >
                    {tab.content && (
                      <Content className="content-main">{tab.content}</Content>
                    )}
                    {tab.showFreeFieldEvaluationForm && (
                      <iframe
                        className="jotform-iframe other-iframe"
                        src="https://form.jotform.com/220856129911053"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment Tabs on WpPage_Sections_Sections_Tabs {
    fieldGroupName
    name
    backgroundColor
    tabs {
      name
      title
      content
      showFreeFieldEvaluationForm
    }
  }
`;

export default Tabs;
