import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

// import PatternColRepeater from "../../images/pattern-column-repeater.svg";
import PatternColTitleContent from "../../images/pattern-column-title-and-content.svg";

const ContentRepeaterColumns = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section clipped-last bg-white section-column-repeater ${
        data.backgroundColor === "gray" ? "bg-gray" : "bg-white"
      }`}
      style={{
        // background: `url(${PatternColRepeater}) no-repeat center center / cover`,
        background: `url(${PatternColTitleContent}) no-repeat center center / cover`,
      }}
    >
      <div className="container sz">
        <div className="row mb-5">
          <div className="col-lg-6">
            <Content className="column-title">{data.title}</Content>
          </div>
        </div>
        <div className="row mb-lg-5">
          {data.contentRepeater &&
            data.contentRepeater.map((item, i) => (
              <div
                key={i}
                className={`col-lg-4 col-column-repeater ${
                  i + 1 !== data.contentRepeater.length ? "mb-2" : ""
                }`}
              >
                <Content className="column-repeater">{item.content}</Content>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContentRepeaterColumns on WpPage_Sections_Sections_ContentRepeaterColumns {
    fieldGroupName
    name
    backgroundColor
    title
    contentRepeater {
      fieldGroupName
      content
    }
  }
`;

export default ContentRepeaterColumns;
