import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql, useStaticQuery } from "gatsby";
import Content from "../utilities/content";
import Map from "../utilities/map";

const ContractsMap = ({ data }) => {
  const {
    wp: {
      themeSettings: {
        themeSettings: { mapContracts: mapData },
      },
    },
  } = useStaticQuery(
    graphql`
      query ContractsMapData {
        wp {
          themeSettings {
            themeSettings {
              mapContracts {
                name
                state
              }
            }
          }
        }
      }
    `
  );

  return (
    <section
      id={convertId(data.name)}
      className="section section-find-sales-rep section-map clip-top contracts-map"
    >
      <div className="container sz">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-last mb-5 mb-lg-0 map-col-parent">
            <Map data={mapData} mapType="contracts" />
          </div>
          <div className="col-lg-6">
            <div className="mb-4 ">
              <Content className="content-find-reps-map">
                {data.content}
              </Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContractsMap on WpPage_Sections_Sections_ContractsMap {
    name
    content
    fieldGroupName
  }
`;

export default ContractsMap;
