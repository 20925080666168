import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import PatternColTitleContent from "../../images/pattern-column-title-and-content.svg";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const ProductDescriptionWithHighlights = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section-product-description-with-highlights section clipped-small-top bg-white"
      style={{
        background: `url(${PatternColTitleContent}) no-repeat center center / cover`,
      }}
    >
      <div className="container sz">
        <div className="row">
          <div className="col-lg-6">
            <Content className="main-title content-title-single-product-highlight display-5 tk-rift text-uppercase text-primary pe-lg-5">
              {data.title}
            </Content>
            <CmsImage className="img-fliud mb-4" image={data.icon} />
            <Content className="description">{data.content}</Content>
            <Content className="bullet-title tk-industry text-secondary text-uppercase">
              {data.bulletTitle}
            </Content>
            {data.bullets && (
              <ul>
                {data.bullets.map((item, key) => (
                  <li className="bullets" key={key}>
                    {item.text}
                  </li>
                ))}
              </ul>
            )}
            {data.bulletsContent && (
              <Content className="content-main content-bullets">
                {data.bulletsContent}
              </Content>
            )}
          </div>
          <div className="col-lg-6">
            <div className="product-highlights">
              <div className="product-highlights-content">
                <Content className="checkmark-title h2 tk-rift text-uppercase text-primary mb-4 pe-lg-5">
                  {data.checkMarkTitle}
                </Content>
                {data.checkMarks && (
                  <ul className="list-checked">
                    {data.checkMarks.map((item, key) => (
                      <li className="checkmark-text" key={key}>
                        {item.text}
                      </li>
                    ))}
                  </ul>
                )}
                {data.checkMarksContent && (
                  <Content className="content-main content-check-marks">
                    {data.checkMarksContent}
                  </Content>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ProductDescriptionWithHighlights on WpPage_Sections_Sections_ProductDescriptionWithHighlights {
    name
    fieldGroupName
    title
    content
    bulletTitle
    checkMarkTitle
    bullets {
      text
    }
    bulletsContent
    checkMarks {
      text
    }
    checkMarksContent
    icon {
      altText
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export default ProductDescriptionWithHighlights;
