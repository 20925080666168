import React, { useState, useRef, useEffect } from "react";
import MapSvg from "./map-svg";
import Content from "./content";
import CmsLink from "./cms-link";

const Map = ({ data, mapArrowLink, mapType }) => {
  const states = {};
  data.forEach((state) => {
    // state.name is the WYSIWYG content. Why they named it that, I don't know, but I don't want to change it so the data doesn't need to be reloaded.
    states[state.state] = state.name;
  });

  const [state, setState] = useState(false);
  const [mapPos, setMapPos] = useState({ left: 0, top: 0 });

  let mapTimeout = null;

  const activeClass = "active";

  const mapParent = useRef(null);
  const mapPopup = useRef(null);
  let currentState = useRef(null);

  const getPos = (client, pos) => client - pos + "px";

  const getMapData = (e) => {
    const elem =
      e.target.parentElement.id === "canada_map" ||
      e.target.parentElement.id === "michigan_map"
        ? e.target.parentElement
        : e.target;

    return {
      id: elem.id.replace("_map", "").replace("_icon", ""),
      elem: elem,
    };
  };

  const hidePopup = () => {
    mapPopup.current?.classList.add(activeClass);
    currentState.current?.classList.remove(activeClass);
    currentState.current = null;

    mapTimeout = setTimeout(() => {
      setState(null);
    }, 500);
  };

  const clickOn = (e) => {
    clearTimeout(mapTimeout);
    mapPopup.current?.classList.remove(activeClass);

    const mapData = getMapData(e);

    currentState.current?.classList.remove(activeClass);

    if (mapData["elem"] === currentState.current) {
      hidePopup();

      return;
    }

    mapData["elem"].classList.add(activeClass);

    setState(mapData["id"]);
    currentState.current = mapData["elem"];

    setMapPos({
      left: getPos(e.clientX, mapParent.current.getBoundingClientRect().left),
      top: getPos(e.clientY, mapParent.current.getBoundingClientRect().top),
    });
  };

  const formatState = (state) => {
    return state
      .replace("_", " ")
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  };

  const clickOutside = (e) => {
    if (e.target.closest(".map-parent path, #floating-sale-rep-info")) return;

    hidePopup();
  };

  const hoverIn = (e) => {
    const mapData = getMapData(e);

    mapData["elem"].classList.add(activeClass);
  };

  const hoverOut = (e) => {
    const mapData = getMapData(e);

    if (mapData["elem"] === currentState.current) return;

    mapData["elem"].classList.remove(activeClass);
  };

  useEffect(() => {
    document.body.addEventListener("click", clickOutside);

    return () => {
      document.body.removeEventListener("click", clickOutside);
    };
  });

  return (
    <div className="map-parent" ref={mapParent}>
      <MapSvg clickOn={clickOn} hoverIn={hoverIn} hoverOut={hoverOut} />
      {state && (
        <div
          id="floating-sale-rep-info"
          className="sale-rep map-popup"
          style={{ "--map-left": mapPos.left, "--map-top": mapPos.top }}
          ref={mapPopup}
          role="tooltip"
        >
          {formatState(state)}
          <hr />
          <Content>{states[state]}</Content>
          {mapArrowLink && (
            <CmsLink className="map-arrow-link" link={mapArrowLink}>
              <svg
                width="20"
                height="9"
                viewBox="0 0 20 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.2595 4.86466C19.4494 4.6692 19.4408 4.35249 19.2405 4.15727L15.9753 0.975906C15.7749 0.780681 15.4585 0.780871 15.2687 0.976329C15.0788 1.17179 15.0874 1.4885 15.2877 1.68372L18.1902 4.5116L15.4401 7.34287C15.2502 7.53833 15.2587 7.85504 15.4591 8.05026C15.6595 8.24548 15.9758 8.24529 16.1657 8.04984L19.2595 4.86466ZM1.01329 5.02211L18.9102 5.01138L18.8833 4.01097L0.986364 4.0217L1.01329 5.02211Z"
                  fill="#08321E"
                />
              </svg>
            </CmsLink>
          )}
        </div>
      )}
    </div>
  );
};

export default Map;
