import React from "react";

const SvgSoccerField = ({
  className,
  keyCount,
  color,
  classRelation,
  ...other
}) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .${classRelation} >
            .swiper-pagination-bullet:nth-child(${keyCount + 1}) {
            background-color: ${color} !important;
            }
        `,
        }}
      />
      <svg
        className={`${className ? className : ""}`}
        width="657"
        height="191"
        viewBox="0 0 657 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2262_43035)">
          <path
            d="M3.28003 188.76L156.49 1.65002H499.33L653.33 188.76H3.28003Z"
            fill={color}
          />
          <path
            d="M56.6499 169.11L173.53 7.97998H484.61L599.42 169.11H56.6499Z"
            stroke="white"
          />
          <path
            d="M329.21 90.7799C346.944 90.7799 361.32 80.3795 361.32 67.5499C361.32 54.7204 346.944 44.3199 329.21 44.3199C311.476 44.3199 297.1 54.7204 297.1 67.5499C297.1 80.3795 311.476 90.7799 329.21 90.7799Z"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M81.27 127.49H159.98L210.81 22.03H156.63"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M113.6 91H136.34L165.77 44.28H146.38"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M198.77 48.73C198.77 48.73 207.46 52.41 204.45 64.09C201.71 74.75 180.04 85.44 180.04 85.44"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M169.77 68.48L171.64 64.73"
            stroke="white"
            stroke-width="0.75"
            stroke-miterlimit="10"
          />
          <path
            d="M61 164.2C61 164.2 63.34 164.87 61.55 168.87"
            stroke="white"
          />
          <path
            d="M596 164.33C596 164.33 593.67 165 595.45 169.01"
            stroke="white"
          />
          <path
            d="M575.77 127.49H497.06L446.22 22.03H500.4"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M543.44 91H520.69L491.26 44.28H510.66"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M458.26 48.73C458.26 48.73 449.56 52.41 452.57 64.09C455.32 74.75 476.99 85.44 476.99 85.44"
            stroke="white"
            stroke-miterlimit="10"
          />
          <path
            d="M487.27 68.48L485.39 64.73"
            stroke="white"
            stroke-width="0.75"
            stroke-miterlimit="10"
          />
          <path
            d="M156.18 1.09998H499.64L654.28 189.3H2.28003L156.18 1.09998Z"
            stroke="white"
            stroke-width="2.2"
            stroke-miterlimit="10"
          />
          <path
            d="M3.28003 188.76L156.49 1.65002H499.33L653.33 188.76H3.28003Z"
            stroke="white"
            stroke-width="2.2"
            stroke-miterlimit="10"
          />
          <path
            d="M329.29 170.53V7.04004"
            stroke="white"
            stroke-miterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_2262_43035">
            <rect width="656.61" height="190.4" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SvgSoccerField;
