import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const Testimonials = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section section-testimonials clipped-small-top bg-white`}
    >
      <img
        className="polygon-bg polygon-bg-top-left"
        src="/images/polygon-testimonials-top-left.svg"
      />
      <div className="container">
        {data.title && (
          <Content className="testimonials-title">{data.title}</Content>
        )}
        {data.testimonials && (
          <div className="testims">
            {data.testimonials.map((testim) => (
              <div className="testimonials-testim">
                <div className="row">
                  <div className="testim-img col-12 col-md-6 mb-5 mb-md-0">
                    <div className="testim-img-shadow">
                      <div className="testim-img-holder">
                        <CmsImage className="testim-img" image={testim.image} />
                      </div>
                    </div>
                  </div>
                  <div className="testim-content-holder col-12 col-md-6 d-flex flex-column justify-content-center">
                    <div className="testim-intro">
                      <span className="testim-name">{testim.name}</span>
                      {testim.location && (
                        <span className="testim-location">
                          {" \\ " + testim.location}
                        </span>
                      )}
                    </div>
                    {testim.title && (
                      <div className="testim-title">{testim.title}</div>
                    )}
                    {testim.content && (
                      <Content className="testim-content">
                        {testim.content}
                      </Content>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <img
        className="polygon-bg polygon-bg-bottom-right"
        src="/images/polygon-testimonials-bottom-right.svg"
      />
    </section>
  );
};

export const query = graphql`
  fragment Testimonials on WpPage_Sections_Sections_Testimonials {
    fieldGroupName
    name
    title
    testimonials {
      name
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
      location
      title
      content
    }
  }
`;

export default Testimonials;
