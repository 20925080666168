import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

const ContentTwoColumns = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section clipped-small-top bg-light section-column-two ${
        data.extraSpacingBottom ? "extra-spacing-bottom" : ""
      } ${data.backgroundColor === "white" ? "bg-white" : "bg-gray"}`}
    >
      <div className="container sz">
        <div className="row">
          {data.contentRepeater &&
            data.contentRepeater.map((item, i) => (
              <div key={i} className="col-lg-6 mb-5 mb-lg-0">
                <Content className="content-two-cols">{item.content}</Content>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContentTwoColumns on WpPage_Sections_Sections_ContentTwoColumns {
    fieldGroupName
    name
    backgroundColor
    extraSpacingBottom
    contentRepeater {
      fieldGroupName
      content
    }
  }
`;

export default ContentTwoColumns;
