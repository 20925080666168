import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";
import CmsLink from "../utilities/cms-link";

const GridImageWithOptionalContent = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`grid-image-content ${
        data.brandsImageSizing ? "brands-image-sizing" : ""
      } ${data.largerImages ? "larger-images" : ""}`}
    >
      <div className="container mx-auto sz">
        <div className="row row-align">
          {data.grid &&
            data.grid.map((item, key) => (
              <div key={key} className="col-sm-6 col-md-4 col-lg-3 image-wrap">
                {item.title && (
                  <Content className="title-grid">{item.title}</Content>
                )}
                {item.image && (
                  <>
                    {item.link ? (
                      <CmsLink link={item.link} target="_blank">
                        <CmsImage
                          className="image-grid"
                          image={item.image}
                          objectFit={
                            data.brandsImageSizing ? "contain" : "cover"
                          }
                        />
                      </CmsLink>
                    ) : (
                      <CmsImage
                        className="image-grid"
                        image={item.image}
                        objectFit={data.brandsImageSizing ? "contain" : "cover"}
                      />
                    )}
                  </>
                )}

                {item.image.caption && (
                  <Content className="wp-caption-text">
                    {item.image.caption}
                  </Content>
                )}

                {item.content && (
                  <Content className="content-grid mt-2">
                    {item.content}
                  </Content>
                )}
              </div>
            ))}
        </div>
        <Content className="">{data.content}</Content>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment GridImageWithOptionalContent on WpPage_Sections_Sections_GridImageWithOptionalContent {
    fieldGroupName
    name
    brandsImageSizing
    largerImages
    grid {
      title
      content
      link {
        target
        title
        url
      }
      image {
        caption
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default GridImageWithOptionalContent;
