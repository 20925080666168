/*
  This component is for handling media screen.
*/

// @TODO File name doesn't follow naming convention of other files. This component is probably not needed if Swiper is reworked.

const useMediaQuery = () => {
  if (typeof window == "undefined") return false;

  if (window.matchMedia("(max-width: 450px)").matches) return true;

  return false;
};

export default useMediaQuery;
