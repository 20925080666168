import React from "react";

// @TODO Implement Recaptcha. This is important. Preferably invisible Recaptcha v3.

const FormElem = (props) => {
  let errorMessage = false;
  if (props.errors) {
    props.errors.forEach((error) => {
      if (error.error_id === "-ve-" + props.name) {
        errorMessage = error.message;
      }
    });
  }

  return (
    <div className="form-control-holder">
      {errorMessage && (
        <div className="bg-red-400 p-2 mb-2">{errorMessage}</div>
      )}
      {props.type !== "textarea" ? (
        <input
          className={
            "mb-5 form-control " + (errorMessage ? "border-red-400" : "")
          }
          name={props.name}
          id={props.name}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          data-lpignore="true"
          placeholder={props.children}
        />
      ) : (
        <textarea
          className="form-control h-[150px]"
          name={props.name}
          id={props.name}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.children}
        />
      )}
    </div>
  );
};

export default class ContactFormUtil extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
      status: null,
      statusMessage: null,
      errors: null,
    };
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Get the form element, action & method, as well as body.
    const formElem = event.target,
      { action, method } = formElem,
      body = new FormData(formElem);

    // Send the request to Contact Form 7.
    fetch(action, {
      method,
      body,
    })
      // Convert response to json.
      .then((response) => response.json())
      .then((response) => {
        console.log(response);

        // response.invalid fields is our errors, otherwise, set this to null.
        const errors = response.invalid_fields ? response.invalid_fields : null;

        // Set state after submitting form.
        this.setState({
          status: response.status,
          statusMessage: response.message,
          errors: errors,
        });

        // Reset needed values if email was sent.
        if (this.state.status === "mail_sent") {
          this.setState({
            Name: "",
            Email: "",
            Phone: "",
            Message: "",
            errors: null,
          });
        }
      })
      .catch((error) => {
        // Uh oh, there was an error.
        console.log(error);

        // This shouldn't happen, but handle it, just in case.
        this.setState({
          status: "error",
          statusMessage:
            "There was an unknown error. Please try contacting us directly instead.",
          errors: null,
        });
      });
  };

  render() {
    return (
      <form
        action={`${process.env.WP_URL}/wp-json/contact-form-7/v1/contact-forms/${process.env.WP_CF7_CONTACT_ID}/feedback`}
        method="post"
        onSubmit={this.handleSubmit}
      >
        {this.state.status && (
          <div
            className={
              "p-4 mb-4 " +
              (this.state.status === "mail_sent"
                ? "bg-green-400"
                : "bg-red-400")
            }
          >
            {this.state.statusMessage}
          </div>
        )}
        <FormElem
          name="Name"
          type="text"
          value={this.state.Name}
          onChange={this.handleInputChange}
          index={0}
          errors={this.state.errors}
        >
          Name*
        </FormElem>
        <FormElem
          name="Email"
          type="email"
          value={this.state.Email}
          onChange={this.handleInputChange}
          index={1}
          errors={this.state.errors}
        >
          Email*
        </FormElem>
        <FormElem
          name="Phone"
          type="tel"
          value={this.state.Phone}
          onChange={this.handleInputChange}
          index={2}
          errors={this.state.errors}
        >
          Phone
        </FormElem>
        <FormElem
          name="Message"
          type="textarea"
          value={this.state.Message}
          onChange={this.handleInputChange}
          index={3}
          errors={this.state.errors}
        >
          Message*
        </FormElem>
        <button
          className="btn btn-primary py-3 px-6 text-white mt-5"
          type="submit"
        >
          Submit
        </button>
      </form>
    );
  }
}
