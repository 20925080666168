import React from "react";

const SvgBaseballField = ({
  className,
  keyCount,
  outfieldColor,
  infieldColor,
  classRelation,
  ...other
}) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .${classRelation} >
            .swiper-pagination-bullet:nth-child(${keyCount + 1}) {
                background-color: ${outfieldColor} !important;
            }
        `,
        }}
      />

      <svg
        className="mx-auto img-fluid d-block"
        width="518"
        height="225"
        viewBox="0 0 518 225"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M258.995 0.573459L517.138 145.543C456.695 198.15 357.884 224.495 259 224.495C160.116 224.495 61.3047 198.15 0.862284 145.543L258.995 0.573459Z"
          fill={outfieldColor}
          stroke="white"
        />
        <path
          d="M340.668 89.4836L341.863 87.8138L269.296 47.0614C272.012 44.3536 271.315 40.8696 267.223 38.568C262.29 35.797 254.288 35.797 249.355 38.568C245.282 40.8606 244.585 44.3175 247.228 47.0073L174.57 87.7957C203.11 132.131 307.223 136.437 340.668 89.4836ZM211.574 79.555C214.072 77.2805 214.063 74.3921 211.574 72.1176L251.663 49.5977C256.078 51.0461 260.846 51.0145 265.241 49.5075L305.72 72.271C303.122 74.5004 303.004 77.3617 305.313 79.6543L265.042 102.237C260.852 100.965 256.371 101.009 252.207 102.364L211.574 79.555Z"
          fill={infieldColor}
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M449.85 151.176L261.485 45.3826"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M66.8013 151.131L255.456 45.1841"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M259.692 45.3828H262.761V42.5938H259.692V45.3828Z"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M253.827 45.3828H256.896V42.5938H253.827V45.3828Z"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          d="M292.043 38.1888C293.353 38.1888 294.415 37.5907 294.415 36.8529C294.415 36.1152 293.353 35.5171 292.043 35.5171C290.734 35.5171 289.672 36.1152 289.672 36.8529C289.672 37.5907 290.734 38.1888 292.043 38.1888Z"
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M258.606 78.1471C263.17 78.1471 266.87 76.07 266.87 73.5078C266.87 70.9455 263.17 68.8684 258.606 68.8684C254.042 68.8684 250.342 70.9455 250.342 73.5078C250.342 76.07 254.042 78.1471 258.606 78.1471Z"
          fill={infieldColor}
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M224.916 38.1888C226.226 38.1888 227.288 37.5907 227.288 36.8529C227.288 36.1152 226.226 35.5171 224.916 35.5171C223.606 35.5171 222.544 36.1152 222.544 36.8529C222.544 37.5907 223.606 38.1888 224.916 38.1888Z"
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M329.236 68.8955L325.616 70.9264L312.102 63.3355L315.722 61.3047"
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M200.522 61.3315L204.143 63.3624L190.619 70.9533L187.008 68.9224"
          stroke="#231F20"
          strokeMiterlimit="10"
        />
        <path
          d="M142.926 197.787L196.566 215.162L223.522 221.381L295.609 221.056L319.732 215.162L372.793 198.743L417.635 176.115L466.939 139.181L377.613 67.8755L279.416 12.5732H238.574L140.101 67.9387L49.7026 138.549L100.989 176.512L142.926 197.787ZM67.3715 138.143L149.452 72.9932L241.191 21.4729L275.669 21.1569L367.996 72.9932L449.805 138.621L406.474 171.241L366.013 191.098L314.364 207.67L292.224 213.086H226.391L204.821 207.986L155.462 192.371L110.964 170.889L67.3715 138.143Z"
          fill={infieldColor}
        />
      </svg>
    </>
  );
};

export default SvgBaseballField;
