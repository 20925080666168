import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { Navigation, EffectCreative } from "swiper";
import CmsImage from "../utilities/cms-image";

const AstroTurfTv = ({ data }) => {
  let swiperAttributes = {
    loop: true,
    autoplay: false,
    navigation: true,
    modules: [Navigation, EffectCreative],
    className: "swiper-tv",
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ["-120%", 0, -700],
      },
      next: {
        shadow: true,
        translate: ["120%", 0, -700],
      },
    },
    centeredSlides: true,
    spaceBetween: -200,
    loopAdditionalSlides: 1,
  };

  const Slide = ({ slide }) => {
    switch (
      slide.fieldGroupName.replace(
        "Page_Sections_Sections_AstroTurfTv_Slides_",
        ""
      )
    ) {
      case "Video":
        return (
          <div className="ratio ratio-16x9">
            <iframe src={slide.video} title="YouTube video" allowFullScreen />
          </div>
        );
      case "Image":
        return (
          <div className="ratio ratio-16x9">
            <div>
              <CmsImage image={slide.image} />
            </div>
          </div>
        );
      case "Content":
        return <Content className="content-slide">{slide.content}</Content>;
      default:
        return null;
    }
  };

  return (
    <section
      id={convertId(data.name)}
      className="section section-product-turf-tv clipped-small-top"
    >
      <img
        className="polygon-bg polygon-bg-bottom-left polygon-bg-desktop"
        src="/images/polygon-astroturf-tv-bottom-left.svg"
      />
      <img
        className="polygon-bg polygon-bg-top-left polygon-bg-mobile"
        src="/images/polygon-astroturf-tv-top-left-mobile.svg"
      />
      <div className="container sz">
        <div className="row">
          <div className="col-lg-6 order-lg-last mb-5 mb-lg-0">
            {data.slides && (
              <>
                {data.slides.length === 1 ? (
                  <>
                    <Slide slide={data.slides[0]} />
                  </>
                ) : (
                  <Swiper {...swiperAttributes}>
                    {data.slides.map((slide) => (
                      <SwiperSlide key={slide.name}>
                        <Slide slide={slide} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                )}
              </>
            )}
          </div>
          <div className="col-lg-6">
            <Content className="mb-4 content-product-turf-tv">
              {data.content}
            </Content>
            {data.buttons &&
              data.buttons.map((button, key) => (
                <CmsLink
                  className="btn btn-primary me-n4 btn-arrow"
                  link={button.buttonLink}
                  key={key}
                />
              ))}
          </div>
        </div>
      </div>
      <img
        className="polygon-bg polygon-bg-center-right polygon-bg-desktop"
        src="/images/polygon-astroturf-tv-center-right.svg"
      />
      <img
        className="polygon-bg polygon-bg-bottom-right polygon-bg-mobile"
        src="/images/polygon-astroturf-tv-bottom-right-mobile.svg"
      />
    </section>
  );
};

export const query = graphql`
  fragment AstroTurfTv on WpPage_Sections_Sections_AstroTurfTv {
    content
    fieldGroupName
    buttons {
      buttonLink {
        target
        title
        url
      }
    }
    name
    slides {
      ... on WpPage_Sections_Sections_AstroTurfTv_Slides_Video {
        fieldGroupName
        name
        video
      }
      ... on WpPage_Sections_Sections_AstroTurfTv_Slides_Image {
        fieldGroupName
        name
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            extension
            publicURL
          }
        }
      }
      ... on WpPage_Sections_Sections_AstroTurfTv_Slides_Content {
        content
        fieldGroupName
        name
      }
    }
  }
`;

export default AstroTurfTv;
