// You must add any sections you create to this file.

import BuildYourOwnField from "../components/sections/build-your-own-field";
import CenteredContent from "../components/sections/centered-content";
import ContentWithImage from "../components/sections/content-with-image";
import ContactForm from "../components/sections/contact-form";
import FindRepsInMap from "../components/sections/find-reps-in-map";
import ContractsMap from "../components/sections/contracts-map";
import ProductSpotlight from "../components/sections/product-spotlight";
import OurProjects from "../components/sections/our-projects";
import AstroTurfTv from "../components/sections/astro-turf-tv";
import SectionTitleWithBackgroundImage from "../components/sections/title-with-background-image";
import SectionColumnTitleAndContent from "../components/sections/column-title-and-content";
import SectionPageLists from "../components/sections/column-page-lists";
import GearUpAndEducation from "../components/sections/gear-up-and-education";
import HomeHero from "../components/sections/home-hero";
import CenteredTitleWithBackgroundImage from "../components/sections/centered-title-with-bg";
import ProductDescriptionWithHighlights from "../components/sections/product-description-with-highlights";
import ProductRootZone from "../components/sections/product-root-zone";
import ContentTwoColumns from "../components/sections/content-two-column";
import ContentRepeaterColumns from "../components/sections/content-repeater-column";
import ProductRootZoneShowcase from "../components/sections/product-root-zone-showcase";
import ProductMoreInfo from "../components/sections/product-more-info";
import ImageGallery from "../components/sections/image-gallery";
import GridImageWithOptionalContent from "../components/sections/grid-image-with-optional-content";
import ColumnCarouselSlider from "../components/sections/column-carousel-slider";
import ContactUsForm from "../components/sections/contact-us-form";
import Team from "../components/sections/team";
import Accordion from "../components/sections/accordion";
import Tabs from "../components/sections/tabs";
import TextWithImageCarousel from "../components/sections/text-with-image-carousel";
import Testimonials from "../components/sections/testimonials";
import TermlySection from "../components/sections/termly-section";

const sectionComponents = {
  HomeHero: HomeHero,
  BuildYourOwnField: BuildYourOwnField,
  CenteredContent: CenteredContent,
  ContentWithImage: ContentWithImage,
  ContactForm: ContactForm,
  FindRepsInMap: FindRepsInMap,
  ContractsMap: ContractsMap,
  ProductSpotlight: ProductSpotlight,
  OurProjects: OurProjects,
  AstroTurfTv: AstroTurfTv,
  SectionTitleWithBackgroundImage: SectionTitleWithBackgroundImage,
  SectionColumnTitleAndContent: SectionColumnTitleAndContent,
  SectionPageLists: SectionPageLists,
  GearUpAndEducation: GearUpAndEducation,
  CenteredTitleWithBackgroundImage: CenteredTitleWithBackgroundImage,
  ProductDescriptionWithHighlights: ProductDescriptionWithHighlights,
  ProductRootZone: ProductRootZone,
  ContentRepeaterColumns: ContentRepeaterColumns,
  ContentTwoColumns: ContentTwoColumns,
  ProductRootZoneShowcase: ProductRootZoneShowcase,
  ProductMoreInfo: ProductMoreInfo,
  ImageGallery: ImageGallery,
  GridImageWithOptionalContent: GridImageWithOptionalContent,
  ColumnCarouselSlider: ColumnCarouselSlider,
  ContactUsForm: ContactUsForm,
  Team: Team,
  Accordion: Accordion,
  Tabs: Tabs,
  TextWithImageCarousel: TextWithImageCarousel,
  Testimonials: Testimonials,
  TermlySection: TermlySection,
};

export default sectionComponents;
