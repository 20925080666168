import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";
import Link from "../utilities/link";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";

const Product = ({ page }) => (
  <div className="product">
    {(page.featuredImage || page.image) && (
      <CmsImage
        className="featured-image img-zoom img-fluid w-100 img-clipped mx-auto d-block "
        image={page.featuredImage ? page.featuredImage.node : page.image}
      />
    )}
  </div>
);

const CenteredProduct = ({ page }) => (
  <>
    <CmsImage
      className="featured-image img-zoom img-fluid w-100 img-clipped mx-auto d-block"
      image={page.featuredImage.node}
    />
    <span className="product-overlay"> {page.title}</span>
  </>
);

const PagesListItem = ({ page, pageListsTitlePosition }) => {
  if (pageListsTitlePosition === "titleTop") {
    return (
      <div className="col-lg-4 col-pages-list">
        <h5 className="tk-industry text-secondary text-uppercase">
          {page.title ? page.title : page.name}
        </h5>

        {page.uri ? (
          <Link to={page.uri} className="d-block page-wrapper">
            <Product page={page} />
          </Link>
        ) : (
          <CmsLink link={page.link} className="d-block page-wrapper">
            <Product page={page} />
          </CmsLink>
        )}
      </div>
    );
  } else if (pageListsTitlePosition === "titleCenter") {
    return (
      <div className="col-lg-4 col-pages-list">
        {page.uri ? (
          <Link to={page.uri} className="d-block product">
            <CenteredProduct page={page} />
          </Link>
        ) : (
          <CmsLink link={page.link} className="d-block product">
            <CenteredProduct page={page} />
          </CmsLink>
        )}
      </div>
    );
  }

  return "";
};

const SectionPageLists = ({ data }) => {
  return (
    <>
      <section
        id={convertId(data.name)}
        className={`section bg-white section-column-page-lists ${
          data.pageListsTitlePosition
        } ${data.removeSlant ? "" : "clipped-small-top"}`}
      >
        <div className="container sz">
          <div className="row">
            {data.content && (
              <div className="content-page-lists">
                <Content>{data.content}</Content>
              </div>
            )}
            {data.pageLists &&
              data.pageLists.map((page, i) => {
                return (
                  <PagesListItem
                    page={page}
                    key={i}
                    pageListsTitlePosition={data.pageListsTitlePosition}
                  />
                );
              })}
            {data.externalLinks &&
              data.externalLinks.map((link) => (
                <PagesListItem
                  page={link}
                  key={link.name}
                  pageListsTitlePosition={data.pageListsTitlePosition}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export const query = graphql`
  fragment SectionPageLists on WpPage_Sections_Sections_SectionPageLists {
    fieldGroupName
    name
    removeSlant
    content
    pageListsTitlePosition
    pageLists {
      ... on WpPage {
        id
        uri
        slug
        title
        link
        featuredImage {
          node {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(width: 351, height: 203)
              }
            }
            altText
          }
        }
      }
      ... on WpPost {
        id
        uri
        slug
        title
        link
        featuredImage {
          node {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            altText
          }
        }
      }
    }
    externalLinks {
      name
      link {
        target
        title
        url
      }
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default SectionPageLists;
