import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import { Swiper, SwiperSlide } from "swiper/react";
import CmsImage from "../utilities/cms-image";
import { EffectCreative, Navigation } from "swiper";

const TextWithImageCarousel = ({ data }) => {
  const swiperEffectY = 2;
  const swiperEffectZ = -330;

  let swiperAttributes = {
    loop: true,
    autoplay: false,
    navigation: true,
    modules: [Navigation, EffectCreative],
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: false,
        translate: [`-76%`, swiperEffectY, swiperEffectZ],
      },
      next: {
        shadow: false,
        translate: [`76%`, swiperEffectY, swiperEffectZ],
      },
    },
    centeredSlides: true,
    spaceBetween: 0,
    loopAdditionalSlides: 1,
    speed: 500,
    breakpoints: {
      576: {
        creativeEffect: {
          prev: {
            translate: [`-93%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`93%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
      768: {
        creativeEffect: {
          prev: {
            translate: [`-98%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`98%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
      992: {
        creativeEffect: {
          prev: {
            translate: [`-84%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`84%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
      1200: {
        creativeEffect: {
          prev: {
            translate: [`-89%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`89%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
      1400: {
        creativeEffect: {
          prev: {
            translate: [`-93%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`93%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
      1800: {
        creativeEffect: {
          prev: {
            translate: [`-96%`, swiperEffectY, swiperEffectZ],
          },
          next: {
            translate: [`96%`, swiperEffectY, swiperEffectZ],
          },
        },
      },
    },
  };

  return (
    <section
      id={convertId(data.name)}
      className={`section section-text-with-image-carousel clip-top ${
        data.backgroundColor === "white" ? "bg-white" : "bg-gray"
      }`}
    >
      <div className="container">
        <div className="grid-text-with-image-carousel">
          <div>
            <Content className="content-main content-text-with-image-carousel">
              {data.content}
            </Content>
          </div>
          <div>
            <Swiper {...swiperAttributes}>
              {data.carousel.map((slide) => (
                <SwiperSlide key={slide.name}>
                  <div className="swiper-slide-outside">
                    <div className="ratio ratio-16x9 ratio-text-with-image-carousel">
                      <div className="swiper-slide-image">
                        <CmsImage image={slide.image} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment TextWithImageCarousel on WpPage_Sections_Sections_TextWithImageCarousel {
    fieldGroupName
    name
    backgroundColor
    content
    carousel {
      name
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default TextWithImageCarousel;
