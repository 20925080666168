import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import useMediaQuery from "../utilities/useMediaQuery";
import Content from "../utilities/content";
import CmsLink from "../utilities/cms-link";
import CmsImage from "../utilities/cms-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { Navigation, EffectCreative } from "swiper";

const OurProjects = ({ data }) => {
  const matches = useMediaQuery();

  let swiperAttributes = {
    className: "section-project-image-swiper swiper-projects",
    direction: "vertical",
    loop: true,
    slidesPerView: 3,
    spaceBetween: 18,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    modules: [Navigation],
  };

  if (matches) {
    delete swiperAttributes.className;
    swiperAttributes.className =
      "section-project-image-swiper swiper-projects-horizontal";
    swiperAttributes.direction = "horizontal";
    swiperAttributes.slidesPerView = 1;
    delete swiperAttributes.centeredSlides;
    delete swiperAttributes.spaceBetween;
  }

  if (data.alternateSliderDisplay) {
    swiperAttributes = {
      className:
        "section-project-image-swiper-alternate swiper-projects-alternate",
      loop: true,
      autoplay: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation, EffectCreative],
      effect: "creative",
      creativeEffect: {
        prev: {
          shadow: false,
          translate: [-40, 0, 0],
          scale: 0.75,
          origin: "left center",
        },
        next: {
          shadow: false,
          translate: [40, 0, 0],
          scale: 0.75,
          origin: "right center",
        },
      },
      centeredSlides: true,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
    };
  }

  const ProjectSlide = ({ image }) => (
    <>
      {image.image && (
        <div
          className="ratio"
          style={{ "--bs-aspect-ratio": "calc(377 / 635 * 100%)" }}
        >
          <div className="img-clipped-holder">
            <CmsImage
              className="img-clipped img-fluid d-block h-100"
              image={image.image}
            />
          </div>
        </div>
      )}
      {image.content && (
        <div className="img-layer">
          <div className="img-description ">
            <Content className="img-content">{image.content}</Content>
            <Content className="img-year">{image.season}</Content>
          </div>
        </div>
      )}
    </>
  );

  return (
    <section
      id={convertId(data.name)}
      className="section section-projects clipped-small-both"
    >
      <div className="container sz">
        <div className="row align-items-center">
          <div className="col-lg-7 order-lg-last section-projects-swiper">
            <Swiper {...swiperAttributes}>
              {data.images &&
                data.images.map((image) => (
                  <SwiperSlide className="img__wrap" key={image.name}>
                    {image.projectLink ? (
                      <CmsLink
                        className="d-block project-slide-link"
                        link={image.projectLink}
                      >
                        <ProjectSlide image={image} />
                      </CmsLink>
                    ) : (
                      <ProjectSlide image={image} />
                    )}
                  </SwiperSlide>
                ))}
              <div className="swiper-button-prev d-none d-lg-block" />
              <div className="swiper-button-next d-none d-lg-block" />
            </Swiper>
          </div>
          <div className="col-lg-5 d-lg-block">
            <Content className="mb-4 content-our-project">
              {data.content}
            </Content>
            {data.buttonLink && (
              <CmsLink
                className="btn btn-primary btn-arrow"
                link={data.buttonLink}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment OurProjects on WpPage_Sections_Sections_OurProjects {
    fieldGroupName
    name
    alternateSliderDisplay
    content
    buttonLink {
      target
      title
      url
    }
    images {
      name
      projectLink {
        target
        title
        url
      }
      content
      season
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(height: 383)
          }
        }
      }
    }
  }
`;

export default OurProjects;
