import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-creative";
import { Navigation, EffectCreative } from "swiper";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const ColumnCarouselSlider = ({ data }) => {
  const swiperTranslateY = "-1.25%",
    swiperTranslateZ = -700;

  let swiperAttributes = {
    loop: true,
    autoplay: false,
    navigation: true,
    modules: [Navigation, EffectCreative],
    className: "swiper-tv swiper-ccs",
    effect: "creative",
    creativeEffect: {
      limitProgress: 2,
      prev: {
        shadow: false,
        translate: ["-45%", swiperTranslateY, swiperTranslateZ],
      },
      next: {
        shadow: false,
        translate: ["45%", swiperTranslateY, swiperTranslateZ],
      },
    },
    centeredSlides: true,
    spaceBetween: 0,
    loopAdditionalSlides: 1,
    speed: 500,
    breakpoints: {
      576: {
        creativeEffect: {
          prev: {
            translate: ["-38%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["38%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
      768: {
        creativeEffect: {
          prev: {
            translate: ["-35%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["35%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
      992: {
        creativeEffect: {
          prev: {
            translate: ["-38%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["38%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
      1200: {
        creativeEffect: {
          prev: {
            translate: ["-38%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["38%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
      1400: {
        creativeEffect: {
          prev: {
            translate: ["-37%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["37%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
      1800: {
        creativeEffect: {
          prev: {
            translate: ["-36%", swiperTranslateY, swiperTranslateZ],
          },
          next: {
            translate: ["36%", swiperTranslateY, swiperTranslateZ],
          },
        },
      },
    },
  };

  return (
    <section
      id={convertId(data.name)}
      className="section section-product-turf-tv section-column-carousel-slider"
    >
      <div className="container sz">
        <div className="row">
          <div className="col-12">
            {data.content && <Content className="mb-5">{data.content}</Content>}
            <Swiper {...swiperAttributes}>
              {data.slides &&
                data.slides.map((slide) => (
                  <SwiperSlide key={slide.name}>
                    <div className="slider-holder">
                      <div className="slider-holder-inside">
                        <div className="ratio ratio-16x9 slider-holder-ratio">
                          <div className="slider-holder-image">
                            <CmsImage image={slide.image} />
                          </div>
                        </div>
                        <Content className="content-slider-overlay">
                          {slide.content}
                        </Content>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ColumnCarouselSlider on WpPage_Sections_Sections_ColumnCarouselSlider {
    fieldGroupName
    name
    content
    slides {
      name
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
      }
      content
    }
  }
`;

export default ColumnCarouselSlider;
