import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const ProductRootZone = ({ data }) => {
  const sectionId = convertId(data.name);

  return (
    <section
      id={sectionId}
      className="section-product-root-zones accordion-section accordion-small section clipped-small-both bg-light"
    >
      <div className="container sz">
        <div
          className={`row row-product-root-zones ${
            data.useTwoColumnLayout ? "row-prod-2-col" : "row-prod-3-col"
          }`}
        >
          {data.rootZones &&
            data.rootZones.map((zone, key) => {
              const accordionId = `accordion-${sectionId}-${key}`,
                accordionItemId = key,
                accordionHeading = `${accordionId}-heading-${accordionItemId}`,
                accordionContent = `${accordionId}-content-${accordionItemId}`;
              return (
                <>
                  {!zone.hideProduct && (
                    <div
                      className={`${
                        data.useTwoColumnLayout ? "col-lg-6" : "col-lg-4"
                      }`}
                      key={key}
                    >
                      <Content className="zone-title tk-industry text-primary text-uppercase mb-4">
                        {zone.title}
                      </Content>
                      {zone.image && (
                        <CmsImage
                          className="img-fluid mb-4"
                          image={zone.image}
                        />
                      )}
                      <div
                        className="accordion accordion-flush"
                        id={accordionId}
                      >
                        <div className="accordion-header" id={accordionHeading}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#" + accordionContent}
                            aria-expanded="false"
                            aria-controls={accordionContent}
                          >
                            <div className="content-main content-accordion-title">
                              <p>
                                {zone.accordionTitle
                                  ? zone.accordionTitle
                                  : "More Info"}
                              </p>
                            </div>
                          </button>
                        </div>
                        <div
                          id={accordionContent}
                          className="accordion-collapse collapse"
                          aria-labelledby={accordionHeading}
                          data-bs-parent={"#" + accordionId}
                        >
                          <div className="accordion-body">
                            <Content className="zone-description">
                              {zone.description}
                            </Content>
                            <Content className="detail-header tk-industry text-secondary text-uppercase mb-3 mt-4">
                              {zone.detailHeader}
                            </Content>
                            <div className="table-responsive">
                              <table className="table table-bordered border-secondary align-middle">
                                <tbody>
                                  {zone.details &&
                                    zone.details.map((detail, detailKey) => (
                                      <tr key={detailKey}>
                                        <td>
                                          <strong className="text-secondary">
                                            {detail.detailKey}
                                          </strong>
                                        </td>
                                        <td>{detail.detailValue}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ProductRootZone on WpPage_Sections_Sections_ProductRootZone {
    fieldGroupName
    name
    useTwoColumnLayout
    rootZones {
      hideProduct
      accordionTitle
      description
      detailHeader
      title
      details {
        detailKey
        detailValue
      }
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ProductRootZone;
