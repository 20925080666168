import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import CmsImage from "../utilities/cms-image";

const SectionTitleWithBackgroundImage = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`page-title section-title-with-background-image ${
        data.gradientOverlay ? "bg-gradient-overlay" : ""
      }`}
    >
      <CmsImage className="section-img" image={data.image} />
      <div className="container">
        <div className="sz wrapper">
          <Content className="content-title-bg-image">{data.content}</Content>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment SectionTitleWithBackgroundImage on WpPage_Sections_Sections_SectionTitleWithBackgroundImage {
    fieldGroupName
    name
    gradientOverlay
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default SectionTitleWithBackgroundImage;
