import React from "react";
import sectionComponents from "../../utilities/sections";

// Loop through the sections and display the correct component
const RenderSections = ({ sections, prefix }) => {
  return (
    <>
      {sections &&
        sections.map((section) => {
          const sectionName = section.fieldGroupName.replace(
              `${prefix ? prefix : "Page"}_Sections_Sections_`,
              ""
            ),
            Section = sectionComponents[sectionName];

          if (section.hideSection) return null;

          return <Section data={section} key={section.name} />;
        })}
    </>
  );
};

export default RenderSections;
