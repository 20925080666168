import React from "react";
import { graphql } from "gatsby";
import Builder from "../components/global/builder";
import IpTracker from "../components/utilities/ip-tracker";

const Page = ({
  data: {
    wpPage: {
      title,
      page,
      sections: { sections },
    },
    wp: {
      themeSettings: { themeSettings },
    },
  },
}) => {
  return (
    <>
      <Builder
        title={title}
        page={page}
        sections={sections}
        themeSettings={themeSettings}
      />
      <IpTracker />
    </>
  );
};

export const pageQuery = graphql`
  query Page($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      page {
        metaTitle
        metaDescription
        pageCssOverrides
      }
      sections {
        sections {
          ... on WpPage_Sections_Sections_HomeHero {
            ...HomeHero
          }
          ... on WpPage_Sections_Sections_CenteredContent {
            ...CenteredContent
          }
          ... on WpPage_Sections_Sections_ContentWithImage {
            ...ContentWithImage
          }
          ... on WpPage_Sections_Sections_ContactForm {
            ...ContactForm
          }
          ... on WpPage_Sections_Sections_FindRepsInMap {
            ...FindRepsInMap
          }
          ... on WpPage_Sections_Sections_ContractsMap {
            ...ContractsMap
          }
          ... on WpPage_Sections_Sections_BuildYourOwnField {
            ...BuildYourOwnField
          }
          ... on WpPage_Sections_Sections_ProductSpotlight {
            ...ProductSpotlight
          }
          ... on WpPage_Sections_Sections_OurProjects {
            ...OurProjects
          }
          ... on WpPage_Sections_Sections_AstroTurfTv {
            ...AstroTurfTv
          }
          ... on WpPage_Sections_Sections_SectionTitleWithBackgroundImage {
            ...SectionTitleWithBackgroundImage
          }
          ... on WpPage_Sections_Sections_SectionColumnTitleAndContent {
            ...SectionColumnTitleAndContent
          }
          ... on WpPage_Sections_Sections_SectionPageLists {
            ...SectionPageLists
          }
          ... on WpPage_Sections_Sections_GearUpAndEducation {
            ...GearUpAndEducation
          }
          ... on WpPage_Sections_Sections_CenteredTitleWithBackgroundImage {
            ...CenteredTitleWithBackgroundImage
          }
          ... on WpPage_Sections_Sections_ProductDescriptionWithHighlights {
            ...ProductDescriptionWithHighlights
          }
          ... on WpPage_Sections_Sections_ProductRootZone {
            ...ProductRootZone
          }
          ... on WpPage_Sections_Sections_ContentTwoColumns {
            ...ContentTwoColumns
          }
          ... on WpPage_Sections_Sections_ContentRepeaterColumns {
            ...ContentRepeaterColumns
          }
          ... on WpPage_Sections_Sections_ProductRootZoneShowcase {
            ...ProductRootZoneShowcase
          }
          ... on WpPage_Sections_Sections_ProductMoreInfo {
            ...ProductMoreInfo
          }
          ... on WpPage_Sections_Sections_ImageGallery {
            ...ImageGallery
          }
          ... on WpPage_Sections_Sections_GridImageWithOptionalContent {
            ...GridImageWithOptionalContent
          }
          ... on WpPage_Sections_Sections_ColumnCarouselSlider {
            ...ColumnCarouselSlider
          }
          ... on WpPage_Sections_Sections_ContactUsForm {
            ...ContactUsForm
          }
          ... on WpPage_Sections_Sections_Team {
            ...Team
          }
          ... on WpPage_Sections_Sections_Accordion {
            ...Accordion
          }
          ... on WpPage_Sections_Sections_Tabs {
            ...Tabs
          }
          ... on WpPage_Sections_Sections_TextWithImageCarousel {
            ...TextWithImageCarousel
          }
          ... on WpPage_Sections_Sections_Testimonials {
            ...Testimonials
          }
          ... on WpPage_Sections_Sections_TermlySection {
            ...TermlySection
          }
        }
      }
    }
    wp {
      themeSettings {
        themeSettings {
          siteMetaTitle
          customCssOverrides
        }
      }
    }
  }
`;

export default Page;
