import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";

import PatternColTitleContent from "../../images/pattern-column-title-and-content.svg";

const SectionColumnTitleAndContent = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className={`section-column-title-and-content section clipped-small-both bg-white ${
        data.spacingBottom ? `spacing-bottom-${data.spacingBottom}` : ""
      } ${data.hideShapeBackground ? "hide-shape-bg" : ""}`}
      style={{
        background: `url(${PatternColTitleContent}) no-repeat center center / cover`,
      }}
    >
      <div className="container sz">
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0">
            <Content className="content-column-title">{data.title}</Content>
          </div>
          <div className="col-lg-6">
            <Content className="content-column-content">{data.content}</Content>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment SectionColumnTitleAndContent on WpPage_Sections_Sections_SectionColumnTitleAndContent {
    fieldGroupName
    name
    spacingBottom
    title
    content
    hideShapeBackground
  }
`;

export default SectionColumnTitleAndContent;
