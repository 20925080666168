import React from "react";

const SvgFootballField = ({
  className,
  keyCount,
  color,
  classRelation,
  ...other
}) => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .${classRelation} >
            .swiper-pagination-bullet:nth-child(${keyCount + 1}) {
            background-color: ${color} !important;
            }
        `,
        }}
      />
      <svg
        className={`${className ? className : ""}`}
        width="626"
        height="242"
        viewBox="0 0 626 242"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_166_7547)">
          <path d="M117 1H510L612 174H14L117 1Z" fill={color} />
          <path
            d="M14.8796 173.5L117.284 1.5H509.714L611.125 173.5H14.8796Z"
            stroke="white"
          />
        </g>
        <g clipPath="url(#clip0_166_7547)">
          <path
            d="M102.237 99.5464H100.786L103.45 94.0635H104.887L102.237 99.5464Z"
            fill="white"
          />
          <path
            d="M107.027 99.5464H105.56L108.164 94.0635H109.616L107.027 99.5464Z"
            fill="white"
          />
          <path
            d="M111.816 99.5464H110.35L112.894 94.0635H114.346L111.816 99.5464Z"
            fill="white"
          />
          <path
            d="M116.606 99.5464H115.139L117.624 94.0635H119.076L116.606 99.5464Z"
            fill="white"
          />
          <path
            d="M123.79 99.5464H122.338L124.733 94.0635H126.17L123.79 99.5464Z"
            fill="white"
          />
          <path
            d="M128.58 99.5464H127.128L129.448 94.0635H130.9L128.58 99.5464Z"
            fill="white"
          />
          <path
            d="M133.369 99.5464H131.902L134.177 94.0635H135.629L133.369 99.5464Z"
            fill="white"
          />
          <path
            d="M138.159 99.5464H136.692L138.907 94.0635H140.359L138.159 99.5464Z"
            fill="white"
          />
          <path
            d="M145.343 99.5464H143.891L146.016 94.0635H147.453L145.343 99.5464Z"
            fill="white"
          />
          <path
            d="M150.132 99.5464H148.681L150.746 94.0635H152.183L150.132 99.5464Z"
            fill="white"
          />
          <path
            d="M154.922 99.5464H153.47L155.476 94.0635H156.913L154.922 99.5464Z"
            fill="white"
          />
          <path
            d="M159.711 99.5464H158.245L160.19 94.0635H161.642L159.711 99.5464Z"
            fill="white"
          />
          <path
            d="M166.911 99.5464H165.444L167.3 94.0635H168.737L166.911 99.5464Z"
            fill="white"
          />
          <path
            d="M171.686 99.5464H170.234L172.03 94.0635H173.467L171.686 99.5464Z"
            fill="white"
          />
          <path
            d="M176.475 99.5464H175.023L176.759 94.0635H178.196L176.475 99.5464Z"
            fill="white"
          />
          <path
            d="M181.264 99.5464H179.812L181.474 94.0635H182.926L181.264 99.5464Z"
            fill="white"
          />
          <path
            d="M188.449 99.5464H186.997L188.584 94.0635H190.02L188.449 99.5464Z"
            fill="white"
          />
          <path
            d="M193.238 99.5464H191.787L193.313 94.0635H194.75L193.238 99.5464Z"
            fill="white"
          />
          <path
            d="M198.028 99.5464H196.576L198.042 94.0635H199.479L198.028 99.5464Z"
            fill="white"
          />
          <path
            d="M202.817 99.5464H201.365L202.757 94.0635H204.209L202.817 99.5464Z"
            fill="white"
          />
          <path
            d="M210.017 99.5464H208.55L209.867 94.0635H211.304L210.017 99.5464Z"
            fill="white"
          />
          <path
            d="M214.806 99.5464H213.339L214.597 94.0635H216.033L214.806 99.5464Z"
            fill="white"
          />
          <path
            d="M219.581 99.5464H218.129L219.326 94.0635H220.763L219.581 99.5464Z"
            fill="white"
          />
          <path
            d="M224.385 99.5464H222.918L224.056 94.0635H225.493L224.385 99.5464Z"
            fill="white"
          />
          <path
            d="M231.569 99.5464H230.103L231.15 94.0635H232.587L231.569 99.5464Z"
            fill="white"
          />
          <path
            d="M236.359 99.5464H234.892L235.88 94.0635H237.317L236.359 99.5464Z"
            fill="white"
          />
          <path
            d="M241.133 99.5464H239.682L240.61 94.0635H242.046L241.133 99.5464Z"
            fill="white"
          />
          <path
            d="M245.923 99.5464H244.471L245.339 94.0635H246.776L245.923 99.5464Z"
            fill="white"
          />
          <path
            d="M253.122 99.5464H251.655L252.434 94.0635H253.87L253.122 99.5464Z"
            fill="white"
          />
          <path
            d="M257.912 99.5464H256.445L257.163 94.0635H258.6L257.912 99.5464Z"
            fill="white"
          />
          <path
            d="M262.701 99.5464H261.234L261.893 94.0635H263.33L262.701 99.5464Z"
            fill="white"
          />
          <path
            d="M267.476 99.5464H266.024L266.623 94.0635H268.059L267.476 99.5464Z"
            fill="white"
          />
          <path
            d="M274.675 99.5464H273.208L273.717 94.0635H275.154L274.675 99.5464Z"
            fill="white"
          />
          <path
            d="M279.465 99.5464H277.998L278.447 94.0635H279.884L279.465 99.5464Z"
            fill="white"
          />
          <path
            d="M284.254 99.5464H282.787L283.176 94.0635H284.613L284.254 99.5464Z"
            fill="white"
          />
          <path
            d="M289.043 99.5464H287.577L287.906 94.0635H289.343L289.043 99.5464Z"
            fill="white"
          />
          <path
            d="M296.228 99.5464H294.761L295 94.0635H296.437L296.228 99.5464Z"
            fill="white"
          />
          <path
            d="M301.018 99.5464H299.551L299.73 94.0635H301.167L301.018 99.5464Z"
            fill="white"
          />
          <path
            d="M305.807 99.5464H304.34L304.46 94.0635H305.896L305.807 99.5464Z"
            fill="white"
          />
          <path
            d="M310.596 99.5464H309.129L309.189 94.0635H310.626L310.596 99.5464Z"
            fill="white"
          />
          <path
            d="M317.78 99.5464H316.314L316.284 94.0635H317.721L317.78 99.5464Z"
            fill="white"
          />
          <path
            d="M322.57 99.5464H321.103L321.014 94.0635H322.451L322.57 99.5464Z"
            fill="white"
          />
          <path
            d="M327.36 99.5464H325.893L325.743 94.0635H327.18L327.36 99.5464Z"
            fill="white"
          />
          <path
            d="M332.149 99.5464H330.682L330.473 94.0635H331.91L332.149 99.5464Z"
            fill="white"
          />
          <path
            d="M339.333 99.5464H337.882L337.567 94.0635H339.019L339.333 99.5464Z"
            fill="white"
          />
          <path
            d="M344.123 99.5464H342.671L342.297 94.0635H343.749L344.123 99.5464Z"
            fill="white"
          />
          <path
            d="M348.912 99.5464H347.445L347.026 94.0635H348.463L348.912 99.5464Z"
            fill="white"
          />
          <path
            d="M353.702 99.5464H352.235L351.756 94.0635H353.193L353.702 99.5464Z"
            fill="white"
          />
          <path
            d="M360.887 99.5464H359.435L358.851 94.0635H360.303L360.887 99.5464Z"
            fill="white"
          />
          <path
            d="M365.676 99.5464H364.224L363.58 94.0635H365.032L365.676 99.5464Z"
            fill="white"
          />
          <path
            d="M370.465 99.5464H369.014L368.31 94.0635H369.747L370.465 99.5464Z"
            fill="white"
          />
          <path
            d="M375.255 99.5464H373.788L373.04 94.0635H374.476L375.255 99.5464Z"
            fill="white"
          />
          <path
            d="M382.439 99.5464H380.987L380.134 94.0635H381.586L382.439 99.5464Z"
            fill="white"
          />
          <path
            d="M387.229 99.5464H385.777L384.864 94.0635H386.316L387.229 99.5464Z"
            fill="white"
          />
          <path
            d="M392.018 99.5464H390.566L389.593 94.0635H391.045L392.018 99.5464Z"
            fill="white"
          />
          <path
            d="M396.808 99.5464H395.356L394.323 94.0635H395.76L396.808 99.5464Z"
            fill="white"
          />
          <path
            d="M403.992 99.5464H402.54L401.417 94.0635H402.869L403.992 99.5464Z"
            fill="white"
          />
          <path
            d="M408.796 99.5464H407.329L406.147 94.0635H407.599L408.796 99.5464Z"
            fill="white"
          />
          <path
            d="M413.571 99.5464H412.119L410.877 94.0635H412.329L413.571 99.5464Z"
            fill="white"
          />
          <path
            d="M418.36 99.5464H416.909L415.606 94.0635H417.058L418.36 99.5464Z"
            fill="white"
          />
          <path
            d="M425.559 99.5464H424.093L422.701 94.0635H424.153L425.559 99.5464Z"
            fill="white"
          />
          <path
            d="M430.334 99.5464H428.882L427.431 94.0635H428.882L430.334 99.5464Z"
            fill="white"
          />
          <path
            d="M435.124 99.5464H433.672L432.16 94.0635H433.612L435.124 99.5464Z"
            fill="white"
          />
          <path
            d="M439.913 99.5464H438.461L436.89 94.0635H438.341L439.913 99.5464Z"
            fill="white"
          />
          <path
            d="M447.112 99.5464H445.645L443.999 94.0635H445.436L447.112 99.5464Z"
            fill="white"
          />
          <path
            d="M451.902 99.5464H450.435L448.714 94.0635H450.166L451.902 99.5464Z"
            fill="white"
          />
          <path
            d="M456.691 99.5464H455.224L453.443 94.0635H454.895L456.691 99.5464Z"
            fill="white"
          />
          <path
            d="M461.481 99.5464H460.014L458.173 94.0635H459.625L461.481 99.5464Z"
            fill="white"
          />
          <path
            d="M468.665 99.5464H467.199L465.283 94.0635H466.72L468.665 99.5464Z"
            fill="white"
          />
          <path
            d="M473.455 99.5464H471.988L469.998 94.0635H471.449L473.455 99.5464Z"
            fill="white"
          />
          <path
            d="M478.244 99.5464H476.778L474.727 94.0635H476.179L478.244 99.5464Z"
            fill="white"
          />
          <path
            d="M483.034 99.5464H481.567L479.457 94.0635H480.908L483.034 99.5464Z"
            fill="white"
          />
          <path
            d="M490.218 99.5464H488.751L486.566 94.0635H488.003L490.218 99.5464Z"
            fill="white"
          />
          <path
            d="M495.008 99.5464H493.541L491.296 94.0635H492.733L495.008 99.5464Z"
            fill="white"
          />
          <path
            d="M499.797 99.5464H498.33L496.01 94.0635H497.462L499.797 99.5464Z"
            fill="white"
          />
          <path
            d="M504.587 99.5464H503.12L500.74 94.0635H502.192L504.587 99.5464Z"
            fill="white"
          />
          <path
            d="M511.771 99.5464H510.304L507.85 94.0635H509.286L511.771 99.5464Z"
            fill="white"
          />
          <path
            d="M516.56 99.5464H515.094L512.579 94.0635H514.016L516.56 99.5464Z"
            fill="white"
          />
          <path
            d="M521.35 99.5464H519.883L517.309 94.0635H518.745L521.35 99.5464Z"
            fill="white"
          />
          <path
            d="M526.139 99.5464H524.673L522.023 94.0635H523.475L526.139 99.5464Z"
            fill="white"
          />
          <path
            d="M127.622 47.0094H126.335L128.4 42.7549H129.673L127.622 47.0094Z"
            fill="white"
          />
          <path
            d="M131.843 47.0094H130.556L132.576 42.7549H133.848L131.843 47.0094Z"
            fill="white"
          />
          <path
            d="M136.048 47.0094H134.761L136.737 42.7549H138.009L136.048 47.0094Z"
            fill="white"
          />
          <path
            d="M140.269 47.0094H138.982L140.913 42.7549H142.17L140.269 47.0094Z"
            fill="white"
          />
          <path
            d="M146.586 47.0094H145.298L147.154 42.7549H148.426L146.586 47.0094Z"
            fill="white"
          />
          <path
            d="M150.806 47.0094H149.519L151.33 42.7549H152.602L150.806 47.0094Z"
            fill="white"
          />
          <path
            d="M155.012 47.0094H153.725L155.491 42.7549H156.763L155.012 47.0094Z"
            fill="white"
          />
          <path
            d="M159.232 47.0094H157.945L159.667 42.7549H160.924L159.232 47.0094Z"
            fill="white"
          />
          <path
            d="M165.549 47.0094H164.262L165.908 42.7549H167.18L165.549 47.0094Z"
            fill="white"
          />
          <path
            d="M169.77 47.0094H168.482L170.084 42.7549H171.356L169.77 47.0094Z"
            fill="white"
          />
          <path
            d="M173.975 47.0094H172.688L174.245 42.7549H175.517L173.975 47.0094Z"
            fill="white"
          />
          <path
            d="M178.196 47.0094H176.909L178.421 42.7549H179.678L178.196 47.0094Z"
            fill="white"
          />
          <path
            d="M184.512 47.0094H183.24L184.677 42.7549H185.949L184.512 47.0094Z"
            fill="white"
          />
          <path
            d="M188.733 47.0094H187.446L188.838 42.7549H190.11L188.733 47.0094Z"
            fill="white"
          />
          <path
            d="M192.939 47.0094H191.652L192.999 42.7549H194.271L192.939 47.0094Z"
            fill="white"
          />
          <path
            d="M197.16 47.0094H195.873L197.175 42.7549H198.447L197.16 47.0094Z"
            fill="white"
          />
          <path
            d="M203.476 47.0094H202.204L203.431 42.7549H204.703L203.476 47.0094Z"
            fill="white"
          />
          <path
            d="M207.697 47.0094H206.41L207.592 42.7549H208.864L207.697 47.0094Z"
            fill="white"
          />
          <path
            d="M211.902 47.0094H210.615L211.753 42.7549H213.025L211.902 47.0094Z"
            fill="white"
          />
          <path
            d="M216.123 47.0094H214.836L215.929 42.7549H217.201L216.123 47.0094Z"
            fill="white"
          />
          <path
            d="M222.439 47.0094H221.167L222.185 42.7549H223.457L222.439 47.0094Z"
            fill="white"
          />
          <path
            d="M226.66 47.0094H225.373L226.346 42.7549H227.618L226.66 47.0094Z"
            fill="white"
          />
          <path
            d="M230.881 47.0094H229.594L230.507 42.7549H231.779L230.881 47.0094Z"
            fill="white"
          />
          <path
            d="M235.087 47.0094H233.799L234.682 42.7549H235.955L235.087 47.0094Z"
            fill="white"
          />
          <path
            d="M241.418 47.0094H240.13L240.939 42.7549H242.211L241.418 47.0094Z"
            fill="white"
          />
          <path
            d="M245.624 47.0094H244.336L245.1 42.7549H246.372L245.624 47.0094Z"
            fill="white"
          />
          <path
            d="M249.844 47.0094H248.557L249.276 42.7549H250.533L249.844 47.0094Z"
            fill="white"
          />
          <path
            d="M254.05 47.0094H252.763L253.436 42.7549H254.708L254.05 47.0094Z"
            fill="white"
          />
          <path
            d="M260.381 47.0094H259.094L259.693 42.7549H260.965L260.381 47.0094Z"
            fill="white"
          />
          <path
            d="M264.587 47.0094H263.3L263.854 42.7549H265.126L264.587 47.0094Z"
            fill="white"
          />
          <path
            d="M268.808 47.0094H267.521L268.029 42.7549H269.302L268.808 47.0094Z"
            fill="white"
          />
          <path
            d="M273.014 47.0094H271.727L272.191 42.7549H273.463L273.014 47.0094Z"
            fill="white"
          />
          <path
            d="M279.345 47.0094H278.058L278.447 42.7549H279.719L279.345 47.0094Z"
            fill="white"
          />
          <path
            d="M283.55 47.0094H282.263L282.607 42.7549H283.88L283.55 47.0094Z"
            fill="white"
          />
          <path
            d="M287.772 47.0094H286.484L286.784 42.7549H288.056L287.772 47.0094Z"
            fill="white"
          />
          <path
            d="M291.977 47.0094H290.69L290.944 42.7549H292.217L291.977 47.0094Z"
            fill="white"
          />
          <path
            d="M298.308 47.0094H297.021L297.201 42.7549H298.473L298.308 47.0094Z"
            fill="white"
          />
          <path
            d="M302.514 47.0094H301.227L301.362 42.7549H302.634L302.514 47.0094Z"
            fill="white"
          />
          <path
            d="M306.735 47.0094H305.448L305.538 42.7549H306.81L306.735 47.0094Z"
            fill="white"
          />
          <path
            d="M310.941 47.0094H309.653L309.698 42.7549H310.97L310.941 47.0094Z"
            fill="white"
          />
          <path
            d="M317.272 47.0094H315.985L315.955 42.7549H317.227L317.272 47.0094Z"
            fill="white"
          />
          <path
            d="M321.477 47.0094H320.19L320.115 42.7549H321.387L321.477 47.0094Z"
            fill="white"
          />
          <path
            d="M325.698 47.0094H324.411L324.292 42.7549H325.564L325.698 47.0094Z"
            fill="white"
          />
          <path
            d="M329.904 47.0094H328.617L328.452 42.7549H329.724L329.904 47.0094Z"
            fill="white"
          />
          <path
            d="M336.235 47.0094H334.948L334.708 42.7549H335.981L336.235 47.0094Z"
            fill="white"
          />
          <path
            d="M340.441 47.0094H339.154L338.87 42.7549H340.142L340.441 47.0094Z"
            fill="white"
          />
          <path
            d="M344.662 47.0094H343.375L343.045 42.7549H344.318L344.662 47.0094Z"
            fill="white"
          />
          <path
            d="M348.867 47.0094H347.58L347.206 42.7549H348.478L348.867 47.0094Z"
            fill="white"
          />
          <path
            d="M355.199 47.0094H353.911L353.462 42.7549H354.735L355.199 47.0094Z"
            fill="white"
          />
          <path
            d="M359.405 47.0094H358.118L357.639 42.7549H358.911L359.405 47.0094Z"
            fill="white"
          />
          <path
            d="M363.625 47.0094H362.338L361.799 42.7549H363.072L363.625 47.0094Z"
            fill="white"
          />
          <path
            d="M367.831 47.0094H366.544L365.96 42.7549H367.233L367.831 47.0094Z"
            fill="white"
          />
          <path
            d="M374.162 47.0094H372.875L372.216 42.7549H373.489L374.162 47.0094Z"
            fill="white"
          />
          <path
            d="M378.368 47.0094H377.096L376.392 42.7549H377.664L378.368 47.0094Z"
            fill="white"
          />
          <path
            d="M382.589 47.0094H381.302L380.553 42.7549H381.825L382.589 47.0094Z"
            fill="white"
          />
          <path
            d="M386.794 47.0094H385.507L384.714 42.7549H385.986L386.794 47.0094Z"
            fill="white"
          />
          <path
            d="M393.126 47.0094H391.839L390.971 42.7549H392.243L393.126 47.0094Z"
            fill="white"
          />
          <path
            d="M397.332 47.0094H396.059L395.146 42.7549H396.419L397.332 47.0094Z"
            fill="white"
          />
          <path
            d="M401.552 47.0094H400.265L399.307 42.7549H400.579L401.552 47.0094Z"
            fill="white"
          />
          <path
            d="M405.758 47.0094H404.486L403.468 42.7549H404.74L405.758 47.0094Z"
            fill="white"
          />
          <path
            d="M412.089 47.0094H410.802L409.724 42.7549H410.996L412.089 47.0094Z"
            fill="white"
          />
          <path
            d="M416.31 47.0094H415.023L413.9 42.7549H415.173L416.31 47.0094Z"
            fill="white"
          />
          <path
            d="M420.516 47.0094H419.228L418.061 42.7549H419.333L420.516 47.0094Z"
            fill="white"
          />
          <path
            d="M424.722 47.0094H423.435L422.222 42.7549H423.494L424.722 47.0094Z"
            fill="white"
          />
          <path
            d="M431.052 47.0094H429.765L428.478 42.7549H429.75L431.052 47.0094Z"
            fill="white"
          />
          <path
            d="M435.274 47.0094H433.986L432.654 42.7549H433.927L435.274 47.0094Z"
            fill="white"
          />
          <path
            d="M439.479 47.0094H438.192L436.815 42.7549H438.087L439.479 47.0094Z"
            fill="white"
          />
          <path
            d="M443.7 47.0094H442.413L440.991 42.7549H442.263L443.7 47.0094Z"
            fill="white"
          />
          <path
            d="M450.016 47.0094H448.729L447.247 42.7549H448.504L450.016 47.0094Z"
            fill="white"
          />
          <path
            d="M454.237 47.0094H452.95L451.408 42.7549H452.68L454.237 47.0094Z"
            fill="white"
          />
          <path
            d="M458.443 47.0094H457.155L455.569 42.7549H456.841L458.443 47.0094Z"
            fill="white"
          />
          <path
            d="M462.663 47.0094H461.376L459.745 42.7549H461.017L462.663 47.0094Z"
            fill="white"
          />
          <path
            d="M468.979 47.0094H467.692L466.001 42.7549H467.273L468.979 47.0094Z"
            fill="white"
          />
          <path
            d="M473.201 47.0094H471.913L470.162 42.7549H471.434L473.201 47.0094Z"
            fill="white"
          />
          <path
            d="M477.406 47.0094H476.119L474.323 42.7549H475.595L477.406 47.0094Z"
            fill="white"
          />
          <path
            d="M481.627 47.0094H480.34L478.499 42.7549H479.771L481.627 47.0094Z"
            fill="white"
          />
          <path
            d="M487.943 47.0094H486.656L484.755 42.7549H486.027L487.943 47.0094Z"
            fill="white"
          />
          <path
            d="M492.164 47.0094H490.877L488.916 42.7549H490.188L492.164 47.0094Z"
            fill="white"
          />
          <path
            d="M496.369 47.0094H495.082L493.077 42.7549H494.349L496.369 47.0094Z"
            fill="white"
          />
          <path
            d="M500.591 47.0094H499.303L497.253 42.7549H498.525L500.591 47.0094Z"
            fill="white"
          />
          <path
            d="M615 174H12L114.585 0H512.474L615 174ZM17.8073 170.869H609.268L510.693 1.37819H116.292L17.8073 170.869Z"
            fill="white"
          />
          <path
            d="M63.1433 172.427H59.2368L144.849 0.688965H147.424L63.1433 172.427Z"
            fill="white"
          />
          <path
            d="M87.0307 172.427H85.8184L162.466 0.688965H163.274L87.0307 172.427Z"
            fill="white"
          />
          <path
            d="M112.265 172.427H111.038L179.199 0.688965H180.007L112.265 172.427Z"
            fill="white"
          />
          <path
            d="M137.5 172.427H136.273L195.947 0.688965H196.756L137.5 172.427Z"
            fill="white"
          />
          <path
            d="M162.72 172.427H161.508L212.681 0.688965H213.489L162.72 172.427Z"
            fill="white"
          />
          <path
            d="M187.955 172.427H186.743L229.414 0.688965H230.223L187.955 172.427Z"
            fill="white"
          />
          <path
            d="M213.19 172.427H211.962L246.148 0.688965H246.956L213.19 172.427Z"
            fill="white"
          />
          <path
            d="M238.425 172.427H237.197L262.881 0.688965H263.689L238.425 172.427Z"
            fill="white"
          />
          <path
            d="M263.644 172.427H262.432L279.629 0.688965H280.437L263.644 172.427Z"
            fill="white"
          />
          <path
            d="M288.879 172.427H287.667L296.362 0.688965H297.171L288.879 172.427Z"
            fill="white"
          />
          <path
            d="M314.114 172.427H312.886L313.096 0.688965H313.904L314.114 172.427Z"
            fill="white"
          />
          <path
            d="M339.333 172.427H338.121L329.829 0.688965H330.637L339.333 172.427Z"
            fill="white"
          />
          <path
            d="M364.568 172.427H363.356L346.562 0.688965H347.371L364.568 172.427Z"
            fill="white"
          />
          <path
            d="M389.803 172.427H388.576L363.311 0.688965H364.119L389.803 172.427Z"
            fill="white"
          />
          <path
            d="M415.038 172.427H413.81L380.044 0.688965H380.853L415.038 172.427Z"
            fill="white"
          />
          <path
            d="M440.258 172.427H439.045L396.778 0.688965H397.586L440.258 172.427Z"
            fill="white"
          />
          <path
            d="M465.492 172.427H464.28L413.511 0.688965H414.319L465.492 172.427Z"
            fill="white"
          />
          <path
            d="M490.727 172.427H489.514L430.244 0.688965H431.067L490.727 172.427Z"
            fill="white"
          />
          <path
            d="M515.962 172.427H514.734L446.993 0.688965H447.801L515.962 172.427Z"
            fill="white"
          />
          <path
            d="M541.182 172.427H539.969L463.726 0.688965H464.534L541.182 172.427Z"
            fill="white"
          />
          <path
            d="M567.763 172.427H563.857L479.576 0.688965H482.166L567.763 172.427Z"
            fill="white"
          />
          <path
            d="M69.2943 167.738H67.603L71.1503 160.442H72.8116L69.2943 167.738Z"
            fill="white"
          />
          <path
            d="M74.8174 167.738H73.1411L76.6135 160.442H78.2749L74.8174 167.738Z"
            fill="white"
          />
          <path
            d="M80.3554 167.738H78.6641L82.0616 160.442H83.723L80.3554 167.738Z"
            fill="white"
          />
          <path
            d="M85.8934 167.738H84.2021L87.5249 160.442H89.1862L85.8934 167.738Z"
            fill="white"
          />
          <path
            d="M94.1998 167.738H92.5234L95.7115 160.442H97.3728L94.1998 167.738Z"
            fill="white"
          />
          <path
            d="M99.7382 167.738H98.0469L101.16 160.442H102.836L99.7382 167.738Z"
            fill="white"
          />
          <path
            d="M105.276 167.738H103.584L106.623 160.442H108.284L105.276 167.738Z"
            fill="white"
          />
          <path
            d="M110.814 167.738H109.123L112.071 160.442H113.732L110.814 167.738Z"
            fill="white"
          />
          <path
            d="M119.12 167.738H117.429L120.258 160.442H121.934L119.12 167.738Z"
            fill="white"
          />
          <path
            d="M124.659 167.738H122.967L125.721 160.442H127.383L124.659 167.738Z"
            fill="white"
          />
          <path
            d="M130.196 167.738H128.505L131.184 160.442H132.845L130.196 167.738Z"
            fill="white"
          />
          <path
            d="M135.719 167.738H134.043L136.632 160.442H138.294L135.719 167.738Z"
            fill="white"
          />
          <path
            d="M144.041 167.738H142.35L144.819 160.442H146.481L144.041 167.738Z"
            fill="white"
          />
          <path
            d="M149.579 167.738H147.888L150.282 160.442H151.944L149.579 167.738Z"
            fill="white"
          />
          <path
            d="M155.102 167.738H153.411L155.731 160.442H157.392L155.102 167.738Z"
            fill="white"
          />
          <path
            d="M160.64 167.738H158.948L161.193 160.442H162.855L160.64 167.738Z"
            fill="white"
          />
          <path
            d="M168.946 167.738H167.27L169.38 160.442H171.042L168.946 167.738Z"
            fill="white"
          />
          <path
            d="M174.484 167.738H172.808L174.829 160.442H176.505L174.484 167.738Z"
            fill="white"
          />
          <path
            d="M180.022 167.738H178.331L180.292 160.442H181.953L180.022 167.738Z"
            fill="white"
          />
          <path
            d="M185.56 167.738H183.869L185.74 160.442H187.401L185.56 167.738Z"
            fill="white"
          />
          <path
            d="M193.867 167.738H192.176L193.942 160.442H195.603L193.867 167.738Z"
            fill="white"
          />
          <path
            d="M199.405 167.738H197.713L199.39 160.442H201.051L199.405 167.738Z"
            fill="white"
          />
          <path
            d="M204.943 167.738H203.251L204.838 160.442H206.514L204.943 167.738Z"
            fill="white"
          />
          <path
            d="M210.48 167.738H208.789L210.301 160.442H211.962L210.48 167.738Z"
            fill="white"
          />
          <path
            d="M218.787 167.738H217.096L218.488 160.442H220.149L218.787 167.738Z"
            fill="white"
          />
          <path
            d="M224.325 167.738H222.634L223.951 160.442H225.612L224.325 167.738Z"
            fill="white"
          />
          <path
            d="M229.863 167.738H228.172L229.399 160.442H231.061L229.863 167.738Z"
            fill="white"
          />
          <path
            d="M235.386 167.738H233.709L234.862 160.442H236.523L235.386 167.738Z"
            fill="white"
          />
          <path
            d="M243.708 167.738H242.017L243.049 160.442H244.711L243.708 167.738Z"
            fill="white"
          />
          <path
            d="M249.231 167.738H247.554L248.497 160.442H250.173L249.231 167.738Z"
            fill="white"
          />
          <path
            d="M254.768 167.738H253.077L253.96 160.442H255.622L254.768 167.738Z"
            fill="white"
          />
          <path
            d="M260.306 167.738H258.615L259.408 160.442H261.07L260.306 167.738Z"
            fill="white"
          />
          <path
            d="M268.613 167.738H266.922L267.61 160.442H269.272L268.613 167.738Z"
            fill="white"
          />
          <path
            d="M274.151 167.738H272.46L273.059 160.442H274.72L274.151 167.738Z"
            fill="white"
          />
          <path
            d="M279.689 167.738H277.998L278.507 160.442H280.183L279.689 167.738Z"
            fill="white"
          />
          <path
            d="M285.227 167.738H283.536L283.97 160.442H285.631L285.227 167.738Z"
            fill="white"
          />
          <path
            d="M293.534 167.738H291.842L292.157 160.442H293.818L293.534 167.738Z"
            fill="white"
          />
          <path
            d="M299.072 167.738H297.38L297.62 160.442H299.281L299.072 167.738Z"
            fill="white"
          />
          <path
            d="M304.609 167.738H302.918L303.068 160.442H304.729L304.609 167.738Z"
            fill="white"
          />
          <path
            d="M310.132 167.738H308.456L308.531 160.442H310.192L310.132 167.738Z"
            fill="white"
          />
          <path
            d="M318.454 167.738H316.763L316.718 160.442H318.379L318.454 167.738Z"
            fill="white"
          />
          <path
            d="M323.977 167.738H322.301L322.166 160.442H323.827L323.977 167.738Z"
            fill="white"
          />
          <path
            d="M329.515 167.738H327.838L327.629 160.442H329.29L329.515 167.738Z"
            fill="white"
          />
          <path
            d="M335.053 167.738H333.362L333.077 160.442H334.738L335.053 167.738Z"
            fill="white"
          />
          <path
            d="M343.375 167.738H341.683L341.279 160.442H342.941L343.375 167.738Z"
            fill="white"
          />
          <path
            d="M348.898 167.738H347.206L346.728 160.442H348.389L348.898 167.738Z"
            fill="white"
          />
          <path
            d="M354.435 167.738H352.744L352.175 160.442H353.852L354.435 167.738Z"
            fill="white"
          />
          <path
            d="M359.974 167.738H358.282L357.639 160.442H359.3L359.974 167.738Z"
            fill="white"
          />
          <path
            d="M368.28 167.738H366.589L365.825 160.442H367.487L368.28 167.738Z"
            fill="white"
          />
          <path
            d="M373.818 167.738H372.127L371.289 160.442H372.95L373.818 167.738Z"
            fill="white"
          />
          <path
            d="M379.356 167.738H377.664L376.736 160.442H378.398L379.356 167.738Z"
            fill="white"
          />
          <path
            d="M384.894 167.738H383.203L382.2 160.442H383.861L384.894 167.738Z"
            fill="white"
          />
          <path
            d="M393.201 167.738H391.509L390.387 160.442H392.048L393.201 167.738Z"
            fill="white"
          />
          <path
            d="M398.739 167.738H397.047L395.835 160.442H397.496L398.739 167.738Z"
            fill="white"
          />
          <path
            d="M404.261 167.738H402.585L401.298 160.442H402.959L404.261 167.738Z"
            fill="white"
          />
          <path
            d="M409.799 167.738H408.108L406.746 160.442H408.407L409.799 167.738Z"
            fill="white"
          />
          <path
            d="M418.121 167.738H416.43L414.933 160.442H416.609L418.121 167.738Z"
            fill="white"
          />
          <path
            d="M423.659 167.738H421.968L420.396 160.442H422.057L423.659 167.738Z"
            fill="white"
          />
          <path
            d="M429.182 167.738H427.491L425.844 160.442H427.521L429.182 167.738Z"
            fill="white"
          />
          <path
            d="M434.72 167.738H433.028L431.307 160.442H432.969L434.72 167.738Z"
            fill="white"
          />
          <path
            d="M443.026 167.738H441.335L439.494 160.442H441.156L443.026 167.738Z"
            fill="white"
          />
          <path
            d="M448.564 167.738H446.873L444.942 160.442H446.619L448.564 167.738Z"
            fill="white"
          />
          <path
            d="M454.102 167.738H452.411L450.405 160.442H452.067L454.102 167.738Z"
            fill="white"
          />
          <path
            d="M459.64 167.738H457.949L455.854 160.442H457.53L459.64 167.738Z"
            fill="white"
          />
          <path
            d="M467.947 167.738H466.255L464.055 160.442H465.717L467.947 167.738Z"
            fill="white"
          />
          <path
            d="M473.485 167.738H471.793L469.503 160.442H471.165L473.485 167.738Z"
            fill="white"
          />
          <path
            d="M479.022 167.738H477.331L474.966 160.442H476.628L479.022 167.738Z"
            fill="white"
          />
          <path
            d="M484.56 167.738H482.869L480.415 160.442H482.076L484.56 167.738Z"
            fill="white"
          />
          <path
            d="M492.867 167.738H491.176L488.602 160.442H490.278L492.867 167.738Z"
            fill="white"
          />
          <path
            d="M498.405 167.738H496.714L494.065 160.442H495.726L498.405 167.738Z"
            fill="white"
          />
          <path
            d="M503.928 167.738H502.252L499.513 160.442H501.174L503.928 167.738Z"
            fill="white"
          />
          <path
            d="M509.466 167.738H507.775L504.976 160.442H506.637L509.466 167.738Z"
            fill="white"
          />
          <path
            d="M517.773 167.738H516.097L513.163 160.442H514.824L517.773 167.738Z"
            fill="white"
          />
          <path
            d="M523.311 167.738H521.619L518.626 160.442H520.287L523.311 167.738Z"
            fill="white"
          />
          <path
            d="M528.849 167.738H527.157L524.074 160.442H525.736L528.849 167.738Z"
            fill="white"
          />
          <path
            d="M534.386 167.738H532.695L529.522 160.442H531.198L534.386 167.738Z"
            fill="white"
          />
          <path
            d="M542.693 167.738H541.002L537.724 160.442H539.385L542.693 167.738Z"
            fill="white"
          />
          <path
            d="M548.231 167.738H546.54L543.172 160.442H544.834L548.231 167.738Z"
            fill="white"
          />
          <path
            d="M553.769 167.738H552.078L548.635 160.442H550.297L553.769 167.738Z"
            fill="white"
          />
          <path
            d="M559.307 167.738H557.616L554.083 160.442H555.745L559.307 167.738Z"
            fill="white"
          />
          <path
            d="M147.349 6.18722H146.196L147.858 2.78662H148.995L147.349 6.18722Z"
            fill="white"
          />
          <path
            d="M151.121 6.18722H149.968L151.585 2.78662H152.722L151.121 6.18722Z"
            fill="white"
          />
          <path
            d="M154.877 6.18722H153.725L155.311 2.78662H156.449L154.877 6.18722Z"
            fill="white"
          />
          <path
            d="M158.649 6.18722H157.497L159.038 2.78662H160.176L158.649 6.18722Z"
            fill="white"
          />
          <path
            d="M164.307 6.18722H163.154L164.636 2.78662H165.774L164.307 6.18722Z"
            fill="white"
          />
          <path
            d="M168.064 6.18722H166.911L168.363 2.78662H169.5L168.064 6.18722Z"
            fill="white"
          />
          <path
            d="M171.835 6.18722H170.683L172.09 2.78662H173.227L171.835 6.18722Z"
            fill="white"
          />
          <path
            d="M175.607 6.18722H174.455L175.817 2.78662H176.969L175.607 6.18722Z"
            fill="white"
          />
          <path
            d="M181.25 6.18722H180.097L181.414 2.78662H182.552L181.25 6.18722Z"
            fill="white"
          />
          <path
            d="M185.021 6.18722H183.869L185.156 2.78662H186.293L185.021 6.18722Z"
            fill="white"
          />
          <path
            d="M188.778 6.18722H187.641L188.883 2.78662H190.02L188.778 6.18722Z"
            fill="white"
          />
          <path
            d="M192.55 6.18722H191.397L192.61 2.78662H193.747L192.55 6.18722Z"
            fill="white"
          />
          <path
            d="M198.208 6.18722H197.055L198.208 2.78662H199.345L198.208 6.18722Z"
            fill="white"
          />
          <path
            d="M201.964 6.18722H200.827L201.934 2.78662H203.072L201.964 6.18722Z"
            fill="white"
          />
          <path
            d="M205.736 6.18722H204.583L205.661 2.78662H206.799L205.736 6.18722Z"
            fill="white"
          />
          <path
            d="M209.507 6.18722H208.355L209.388 2.78662H210.525L209.507 6.18722Z"
            fill="white"
          />
          <path
            d="M215.15 6.18722H214.013L214.986 2.78662H216.123L215.15 6.18722Z"
            fill="white"
          />
          <path
            d="M218.922 6.18722H217.77L218.727 2.78662H219.865L218.922 6.18722Z"
            fill="white"
          />
          <path
            d="M222.694 6.18722H221.542L222.44 2.78662H223.592L222.694 6.18722Z"
            fill="white"
          />
          <path
            d="M226.45 6.18722H225.298L226.181 2.78662H227.318L226.45 6.18722Z"
            fill="white"
          />
          <path
            d="M232.108 6.18722H230.956L231.779 2.78662H232.916L232.108 6.18722Z"
            fill="white"
          />
          <path
            d="M235.88 6.18722H234.728L235.506 2.78662H236.643L235.88 6.18722Z"
            fill="white"
          />
          <path
            d="M239.637 6.18722H238.484L239.233 2.78662H240.37L239.637 6.18722Z"
            fill="white"
          />
          <path
            d="M243.408 6.18722H242.256L242.959 2.78662H244.097L243.408 6.18722Z"
            fill="white"
          />
          <path
            d="M249.066 6.18722H247.914L248.557 2.78662H249.695L249.066 6.18722Z"
            fill="white"
          />
          <path
            d="M252.823 6.18722H251.67L252.284 2.78662H253.422L252.823 6.18722Z"
            fill="white"
          />
          <path
            d="M256.594 6.18722H255.442L256.011 2.78662H257.148L256.594 6.18722Z"
            fill="white"
          />
          <path
            d="M260.366 6.18722H259.214L259.753 2.78662H260.875L260.366 6.18722Z"
            fill="white"
          />
          <path
            d="M266.009 6.18722H264.856L265.335 2.78662H266.488L266.009 6.18722Z"
            fill="white"
          />
          <path
            d="M269.781 6.18722H268.628L269.077 2.78662H270.215L269.781 6.18722Z"
            fill="white"
          />
          <path
            d="M273.552 6.18722H272.4L272.804 2.78662H273.942L273.552 6.18722Z"
            fill="white"
          />
          <path
            d="M277.309 6.18722H276.157L276.531 2.78662H277.668L277.309 6.18722Z"
            fill="white"
          />
          <path
            d="M282.967 6.18722H281.814L282.129 2.78662H283.266L282.967 6.18722Z"
            fill="white"
          />
          <path
            d="M286.723 6.18722H285.586L285.855 2.78662H286.993L286.723 6.18722Z"
            fill="white"
          />
          <path
            d="M290.495 6.18722H289.343L289.582 2.78662H290.72L290.495 6.18722Z"
            fill="white"
          />
          <path
            d="M294.267 6.18722H293.115L293.309 2.78662H294.447L294.267 6.18722Z"
            fill="white"
          />
          <path
            d="M299.91 6.18722H298.772L298.907 2.78662H300.045L299.91 6.18722Z"
            fill="white"
          />
          <path
            d="M303.681 6.18722H302.529L302.649 2.78662H303.786L303.681 6.18722Z"
            fill="white"
          />
          <path
            d="M307.453 6.18722H306.301L306.376 2.78662H307.513L307.453 6.18722Z"
            fill="white"
          />
          <path
            d="M311.21 6.18722H310.072L310.102 2.78662H311.24L311.21 6.18722Z"
            fill="white"
          />
          <path
            d="M316.868 6.18722H315.715L315.7 2.78662H316.838L316.868 6.18722Z"
            fill="white"
          />
          <path
            d="M320.639 6.18722H319.487L319.427 2.78662H320.564L320.639 6.18722Z"
            fill="white"
          />
          <path
            d="M324.396 6.18722H323.259L323.154 2.78662H324.291L324.396 6.18722Z"
            fill="white"
          />
          <path
            d="M328.168 6.18722H327.016L326.881 2.78662H328.018L328.168 6.18722Z"
            fill="white"
          />
          <path
            d="M333.826 6.18722H332.673L332.479 2.78662H333.616L333.826 6.18722Z"
            fill="white"
          />
          <path
            d="M337.582 6.18722H336.43L336.205 2.78662H337.343L337.582 6.18722Z"
            fill="white"
          />
          <path
            d="M341.354 6.18722H340.202L339.932 2.78662H341.085L341.354 6.18722Z"
            fill="white"
          />
          <path
            d="M345.111 6.18722H343.973L343.674 2.78662H344.811L345.111 6.18722Z"
            fill="white"
          />
          <path
            d="M350.768 6.18722H349.616L349.271 2.78662H350.409L350.768 6.18722Z"
            fill="white"
          />
          <path
            d="M354.54 6.18722H353.388L352.999 2.78662H354.136L354.54 6.18722Z"
            fill="white"
          />
          <path
            d="M358.297 6.18722H357.16L356.726 2.78662H357.863L358.297 6.18722Z"
            fill="white"
          />
          <path
            d="M362.069 6.18722H360.916L360.452 2.78662H361.59L362.069 6.18722Z"
            fill="white"
          />
          <path
            d="M367.726 6.18722H366.574L366.05 2.78662H367.187L367.726 6.18722Z"
            fill="white"
          />
          <path
            d="M371.483 6.18722H370.346L369.777 2.78662H370.914L371.483 6.18722Z"
            fill="white"
          />
          <path
            d="M375.255 6.18722H374.102L373.503 2.78662H374.641L375.255 6.18722Z"
            fill="white"
          />
          <path
            d="M379.026 6.18722H377.874L377.23 2.78662H378.368L379.026 6.18722Z"
            fill="white"
          />
          <path
            d="M384.669 6.18722H383.532L382.828 2.78662H383.981L384.669 6.18722Z"
            fill="white"
          />
          <path
            d="M388.441 6.18722H387.288L386.57 2.78662H387.707L388.441 6.18722Z"
            fill="white"
          />
          <path
            d="M392.213 6.18722H391.06L390.297 2.78662H391.434L392.213 6.18722Z"
            fill="white"
          />
          <path
            d="M395.97 6.18722H394.832L394.024 2.78662H395.161L395.97 6.18722Z"
            fill="white"
          />
          <path
            d="M401.627 6.18722H400.475L399.622 2.78662H400.759L401.627 6.18722Z"
            fill="white"
          />
          <path
            d="M405.399 6.18722H404.247L403.349 2.78662H404.486L405.399 6.18722Z"
            fill="white"
          />
          <path
            d="M409.156 6.18722H408.003L407.075 2.78662H408.213L409.156 6.18722Z"
            fill="white"
          />
          <path
            d="M412.928 6.18722H411.775L410.802 2.78662H411.94L412.928 6.18722Z"
            fill="white"
          />
          <path
            d="M418.585 6.18722H417.433L416.4 2.78662H417.537L418.585 6.18722Z"
            fill="white"
          />
          <path
            d="M422.342 6.18722H421.189L420.126 2.78662H421.264L422.342 6.18722Z"
            fill="white"
          />
          <path
            d="M426.114 6.18722H424.961L423.869 2.78662H425.006L426.114 6.18722Z"
            fill="white"
          />
          <path
            d="M429.87 6.18722H428.733L427.595 2.78662H428.733L429.87 6.18722Z"
            fill="white"
          />
          <path
            d="M435.528 6.18722H434.375L433.193 2.78662H434.33L435.528 6.18722Z"
            fill="white"
          />
          <path
            d="M439.3 6.18722H438.147L436.92 2.78662H438.057L439.3 6.18722Z"
            fill="white"
          />
          <path
            d="M443.057 6.18722H441.919L440.647 2.78662H441.784L443.057 6.18722Z"
            fill="white"
          />
          <path
            d="M446.828 6.18722H445.676L444.374 2.78662H445.511L446.828 6.18722Z"
            fill="white"
          />
          <path
            d="M452.486 6.18722H451.333L449.971 2.78662H451.109L452.486 6.18722Z"
            fill="white"
          />
          <path
            d="M456.243 6.18722H455.105L453.698 2.78662H454.836L456.243 6.18722Z"
            fill="white"
          />
          <path
            d="M460.014 6.18722H458.862L457.425 2.78662H458.562L460.014 6.18722Z"
            fill="white"
          />
          <path
            d="M463.786 6.18722H462.634L461.152 2.78662H462.289L463.786 6.18722Z"
            fill="white"
          />
          <path
            d="M469.444 6.18722H468.291L466.765 2.78662H467.902L469.444 6.18722Z"
            fill="white"
          />
          <path
            d="M473.2 6.18722H472.048L470.491 2.78662H471.629L473.2 6.18722Z"
            fill="white"
          />
          <path
            d="M476.972 6.18722H475.82L474.218 2.78662H475.356L476.972 6.18722Z"
            fill="white"
          />
          <path
            d="M480.729 6.18722H479.576L477.945 2.78662H479.082L480.729 6.18722Z"
            fill="white"
          />
          <path
            d="M90.1141 56.0273H76L77.0477 54.2896H91.1019L90.1141 56.0273Z"
            fill="white"
          />
          <path
            d="M73.5306 85.2398H58.3838L59.5961 83.2324H74.6831L73.5306 85.2398Z"
            fill="white"
          />
          <path
            d="M551 56.0273H536.886L535.898 54.2896H549.952L551 56.0273Z"
            fill="white"
          />
          <path
            d="M568.616 85.2398H553.47L552.332 83.2324H567.404L568.616 85.2398Z"
            fill="white"
          />
          <path
            d="M120.602 74.2137H119.121L123.805 64.2666H125.257L120.602 74.2137Z"
            fill="white"
          />
          <path
            d="M507.7 74.2137H506.218L501.563 64.2666H503.015L507.7 74.2137Z"
            fill="white"
          />
          <path
            d="M363.086 21.9616L360.781 20.7632L361.096 23.1601L363.086 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M398.319 21.9616L395.895 20.7632L396.448 23.1601L398.319 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M433.687 21.9616L431.157 20.7632L431.936 23.1601L433.687 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M466.704 21.9616L464.055 20.7632L465.058 23.1601L466.704 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M264.512 21.9616L266.817 20.7632L266.503 23.1601L264.512 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M229.07 21.9616L231.495 20.7632L230.941 23.1601L229.07 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M193.538 21.9616L196.067 20.7632L195.274 23.1601L193.538 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M157.96 21.9616L160.625 20.7632L159.592 23.1601L157.96 21.9616Z"
            fill="#F2F2F2"
          />
          <path
            d="M456.722 18.8755H458.832L460.673 23.3697C461.398 22.9093 462.207 22.5986 463.053 22.4558L463.517 23.5494C462.96 23.6651 462.425 23.8673 461.93 24.1487C461.712 24.243 461.517 24.3826 461.357 24.5582C461.197 24.7338 461.076 24.9414 461.002 25.1673H459.266L456.722 18.8755Z"
            fill="#F2F2F2"
          />
          <path
            d="M451.229 25.1973C450.255 25.2211 449.291 24.9941 448.43 24.5381C447.461 23.9483 446.738 23.0278 446.394 21.9464C445.96 20.763 446.005 19.924 446.529 19.3997C447.178 18.9168 447.984 18.6926 448.789 18.7705C449.785 18.7496 450.77 18.987 451.648 19.4596C452.577 20.0347 453.272 20.9213 453.608 21.9614C454.072 23.1599 454.042 24.0138 453.519 24.5381C452.866 25.0403 452.048 25.2758 451.229 25.1973ZM450.839 24.1785C451.062 24.1881 451.283 24.1418 451.483 24.0437C451.567 23.9872 451.637 23.9116 451.686 23.8231C451.736 23.7346 451.763 23.6357 451.767 23.5344C451.736 22.9857 451.604 22.4475 451.378 21.9464C451.195 21.411 450.933 20.906 450.6 20.4484C450.413 20.214 450.171 20.0289 449.896 19.9091C449.663 19.8183 449.414 19.7725 449.163 19.7742C448.946 19.7659 448.729 19.8123 448.534 19.9091C448.448 19.9592 448.376 20.0312 448.326 20.118C448.276 20.2048 448.25 20.3033 448.25 20.4034C448.276 20.9224 448.403 21.4312 448.624 21.9015C448.793 22.4402 449.051 22.9466 449.388 23.3996C449.558 23.6333 449.784 23.8203 450.046 23.9433C450.308 24.0664 450.596 24.1215 450.884 24.1037L450.839 24.1785Z"
            fill="#F2F2F2"
          />
          <path
            d="M421.758 20.029L421.429 18.9204H428.897C428.927 19.1308 428.912 19.3452 428.852 19.5492C428.793 19.7533 428.691 19.9423 428.553 20.1038C428.004 20.7019 427.362 21.2077 426.652 21.6019C426.12 21.8962 425.619 22.2424 425.156 22.6356C425.058 22.7311 424.987 22.8506 424.951 22.9821C424.914 23.1135 424.912 23.2523 424.946 23.3846C424.994 23.5229 425.07 23.6494 425.171 23.7554C425.272 23.8614 425.394 23.9444 425.53 23.9988C425.881 24.1596 426.266 24.2365 426.652 24.2235C427.001 24.2471 427.35 24.1691 427.655 23.9988C427.763 23.9072 427.84 23.7854 427.877 23.6491C427.915 23.5129 427.91 23.3685 427.865 23.2348L430.035 23.3547C430.116 23.6509 430.087 23.9668 429.952 24.2428C429.817 24.5188 429.585 24.7357 429.301 24.8527C428.554 25.1855 427.739 25.3393 426.922 25.3021C425.973 25.3268 425.03 25.1533 424.153 24.7928C423.855 24.6877 423.583 24.5195 423.356 24.2998C423.128 24.08 422.951 23.8139 422.836 23.5194C422.738 23.2586 422.738 22.9713 422.836 22.7105C422.983 22.4017 423.192 22.1264 423.449 21.9015C423.807 21.607 424.187 21.3415 424.587 21.1076C425.156 20.763 425.5 20.5233 425.649 20.4185C425.747 20.2977 425.833 20.1672 425.904 20.029H421.758Z"
            fill="#F2F2F2"
          />
          <path
            d="M415.337 25.1974C414.381 25.2291 413.435 25.0013 412.598 24.5382C411.686 23.9429 411.046 23.0116 410.817 21.9466C410.627 21.5302 410.563 21.0675 410.632 20.6151C410.701 20.1628 410.901 19.7404 411.206 19.3998C411.881 18.9228 412.703 18.6999 413.526 18.7707C414.499 18.7414 415.462 18.9797 416.31 19.4598C417.183 20.0416 417.799 20.9374 418.031 21.9615C418.375 23.16 418.255 24.0139 417.672 24.5382C417 25.0355 416.169 25.2699 415.337 25.1974ZM415.038 24.1787C415.264 24.1843 415.49 24.1382 415.696 24.0438C415.786 23.9897 415.863 23.9157 415.92 23.8274C415.977 23.7391 416.013 23.6389 416.025 23.5345C416.058 22.9954 415.982 22.4553 415.801 21.9466C415.674 21.4164 415.461 20.9105 415.172 20.4485C415.009 20.2146 414.787 20.0285 414.529 19.9092C414.3 19.8185 414.056 19.7727 413.81 19.7743C413.583 19.7687 413.358 19.8149 413.152 19.9092C413.063 19.961 412.987 20.0324 412.93 20.1181C412.873 20.2038 412.836 20.3014 412.822 20.4035C412.798 20.9128 412.874 21.4219 413.047 21.9016C413.159 22.434 413.367 22.9416 413.661 23.3997C413.829 23.6419 414.055 23.8377 414.319 23.9689C414.542 24.0854 414.787 24.1567 415.038 24.1787Z"
            fill="#F2F2F2"
          />
          <path
            d="M393.874 20.5235L391.853 20.6583C391.708 20.3662 391.472 20.1295 391.18 19.9842C390.836 19.8307 390.464 19.7541 390.087 19.7595C389.719 19.7404 389.353 19.8345 389.04 20.0291C388.919 20.1112 388.827 20.2289 388.776 20.3657C388.725 20.5026 388.718 20.652 388.755 20.7932C388.791 20.9498 388.862 21.096 388.964 21.2207C389.065 21.3454 389.193 21.4451 389.339 21.5122C389.678 21.6979 390.06 21.7909 390.447 21.7819C390.768 21.777 391.088 21.7469 391.405 21.692V22.6657C390.968 22.6298 390.53 22.7072 390.132 22.8904C390.015 22.9344 389.917 23.0189 389.857 23.1289C389.796 23.2389 389.777 23.3669 389.803 23.4897C389.835 23.603 389.891 23.7077 389.969 23.7959C390.047 23.8841 390.144 23.9535 390.252 23.999C390.542 24.1345 390.86 24.2012 391.18 24.1937C391.49 24.2078 391.797 24.1296 392.063 23.969C392.173 23.9059 392.263 23.8138 392.323 23.7028C392.384 23.5919 392.413 23.4662 392.407 23.3398L394.413 23.5346C394.383 23.8784 394.234 24.2013 393.994 24.4484C393.705 24.7214 393.348 24.9127 392.961 25.0027C392.428 25.1355 391.879 25.201 391.33 25.1975C390.412 25.2268 389.501 25.026 388.68 24.6132C388.082 24.2986 387.752 23.954 387.678 23.5645C387.603 23.175 388.007 22.5609 388.995 22.2313C388.419 22.1781 387.862 21.9991 387.363 21.707C387.176 21.6101 387.011 21.4745 386.879 21.3093C386.748 21.1441 386.652 20.9529 386.6 20.7482C386.48 20.1939 386.75 19.7295 387.378 19.34C388.149 18.9253 389.019 18.7285 389.893 18.7708C390.775 18.7519 391.651 18.9151 392.467 19.2502C393.079 19.4825 393.582 19.9371 393.874 20.5235Z"
            fill="#F2F2F2"
          />
          <path
            d="M379.505 25.1973C378.578 25.2335 377.659 25.0049 376.856 24.5382C376.437 24.2378 376.086 23.8511 375.828 23.4042C375.57 22.9572 375.41 22.4603 375.359 21.9465C375.225 21.5036 375.214 21.0324 375.328 20.5837C375.441 20.135 375.675 19.7257 376.003 19.3998C376.704 18.9296 377.541 18.7081 378.383 18.7706C379.338 18.7358 380.283 18.9749 381.107 19.4597C381.52 19.7462 381.867 20.1176 382.125 20.549C382.383 20.9804 382.546 21.462 382.603 21.9615C382.752 22.4043 382.775 22.8798 382.67 23.3349C382.564 23.79 382.334 24.2066 382.005 24.5382C381.276 25.0469 380.39 25.2805 379.505 25.1973ZM379.326 24.1786C379.557 24.1834 379.787 24.1374 379.999 24.0438C380.093 23.9891 380.175 23.9156 380.239 23.828C380.303 23.7404 380.349 23.6405 380.373 23.5345C380.455 23.0055 380.43 22.4654 380.298 21.9465C380.228 21.4229 380.066 20.9157 379.819 20.4485C379.681 20.2157 379.479 20.0284 379.236 19.9092C379.008 19.817 378.763 19.7711 378.517 19.7743C378.29 19.7687 378.065 19.8148 377.859 19.9092C377.765 19.9608 377.684 20.0315 377.619 20.1166C377.555 20.2018 377.509 20.2995 377.485 20.4035C377.384 20.8978 377.384 21.4073 377.485 21.9016C377.55 22.4243 377.707 22.9314 377.949 23.3996C378.096 23.6376 378.302 23.8337 378.547 23.9689C378.771 24.0854 379.015 24.1567 379.266 24.1786H379.326Z"
            fill="#F2F2F2"
          />
          <path
            d="M354.316 18.8755L354.465 20.1189H358.955L359.09 21.1675L354.735 25.1973H352.939L352.505 21.1825H351.128L351.008 20.1189H352.385L352.25 18.8755H354.316ZM354.585 21.1825L354.825 23.3247L357.129 21.1825H354.585Z"
            fill="#F2F2F2"
          />
          <path
            d="M343.584 25.1972C342.675 25.24 341.773 25.0105 340.995 24.5381C340.594 24.2357 340.269 23.8442 340.046 23.3943C339.822 22.9445 339.707 22.4488 339.708 21.9464C339.628 21.4805 339.669 21.0019 339.826 20.5561C339.983 20.1104 340.251 19.7123 340.606 19.3997C341.336 18.934 342.196 18.7136 343.06 18.7705C343.992 18.733 344.914 18.9728 345.71 19.4596C346.088 19.7573 346.394 20.1372 346.604 20.5706C346.814 21.0041 346.923 21.4797 346.922 21.9614C347.017 22.4281 346.988 22.9115 346.838 23.3636C346.689 23.8156 346.423 24.2206 346.069 24.5381C345.335 25.0236 344.462 25.2551 343.584 25.1972ZM343.494 24.1785C343.731 24.1846 343.966 24.1385 344.183 24.0437C344.386 23.9338 344.541 23.7522 344.617 23.5344C344.746 23.0151 344.771 22.4755 344.692 21.9464C344.682 21.4302 344.57 20.921 344.363 20.4483C344.248 20.2133 344.059 20.0235 343.824 19.9091C343.606 19.8156 343.372 19.7696 343.135 19.7742C342.904 19.7694 342.674 19.8154 342.462 19.9091C342.26 20.013 342.105 20.1896 342.028 20.4034C341.9 20.892 341.869 21.4011 341.938 21.9015C341.951 22.4158 342.057 22.9235 342.252 23.3995C342.375 23.6407 342.568 23.839 342.806 23.9688C343 24.0792 343.213 24.1504 343.435 24.1785H343.494Z"
            fill="#F2F2F2"
          />
          <path
            d="M323.094 20.4783L320.983 20.5982C320.947 20.4574 320.88 20.3264 320.787 20.215C320.694 20.1035 320.577 20.0144 320.445 19.9541C320.123 19.7991 319.769 19.722 319.412 19.7293C319.016 19.7143 318.625 19.8187 318.289 20.0289C318.14 20.1268 318.021 20.2638 317.944 20.425C317.868 20.5861 317.837 20.7652 317.855 20.9427C317.845 21.1163 317.883 21.2893 317.965 21.4426C318.047 21.5959 318.169 21.7236 318.319 21.8116C318.683 22.0194 319.099 22.1182 319.517 22.0963C320.095 22.1084 320.663 21.9466 321.148 21.6319L322.884 21.7817L321.866 25.0774H316.194V23.9389H320.235L320.549 22.8603C320.062 22.996 319.558 23.0615 319.053 23.055C318.211 23.0812 317.379 22.8793 316.643 22.4708C316.368 22.3551 316.13 22.1655 315.956 21.9231C315.782 21.6806 315.679 21.3947 315.657 21.0969C315.635 20.7992 315.696 20.5013 315.833 20.2361C315.97 19.9709 316.178 19.7489 316.433 19.5945C317.31 18.9906 318.365 18.7003 319.427 18.7706C320.279 18.7374 321.128 18.8961 321.911 19.235C322.191 19.336 322.444 19.501 322.649 19.7168C322.855 19.9327 323.007 20.1934 323.094 20.4783Z"
            fill="#F2F2F2"
          />
          <path
            d="M307.663 25.1976C306.771 25.2503 305.886 25.0196 305.133 24.5384C304.758 24.2279 304.466 23.8277 304.286 23.3748C304.105 22.9219 304.042 22.4308 304.1 21.9468C304.071 21.4605 304.161 20.9744 304.362 20.5306C304.562 20.0869 304.868 19.6988 305.253 19.4001C306.032 18.9686 306.907 18.7422 307.797 18.7422C308.687 18.7422 309.563 18.9686 310.342 19.4001C310.699 19.7031 310.975 20.0906 311.145 20.5275C311.315 20.9644 311.373 21.4367 311.315 21.9018C311.354 22.3892 311.274 22.8788 311.081 23.3279C310.888 23.777 310.588 24.1721 310.207 24.4785C309.454 24.9763 308.565 25.2276 307.663 25.1976ZM307.663 24.1789C307.899 24.1835 308.134 24.1375 308.351 24.0441C308.567 23.9373 308.737 23.7565 308.83 23.5347C309.018 23.0275 309.1 22.4869 309.07 21.9468C309.113 21.4404 309.052 20.9304 308.89 20.4487C308.801 20.2165 308.631 20.0247 308.411 19.9094C308.199 19.8158 307.969 19.7698 307.737 19.7746C307.501 19.77 307.266 19.816 307.049 19.9094C306.834 20.0092 306.663 20.1853 306.57 20.4038C306.39 20.8822 306.304 21.3908 306.316 21.9018C306.277 22.4071 306.333 22.9151 306.48 23.3999C306.578 23.6385 306.752 23.8384 306.974 23.9692C307.186 24.0886 307.421 24.1602 307.663 24.1789Z"
            fill="#F2F2F2"
          />
          <path
            d="M283.58 18.8755L283.475 20.1189H287.966L287.891 21.1675L282.742 25.1973H280.946L281.305 21.1825H279.928L280.018 20.1189H281.395L281.515 18.8755H283.58ZM283.386 21.1825L283.206 23.3247L285.93 21.1825H283.386Z"
            fill="#F2F2F2"
          />
          <path
            d="M271.607 25.1973C270.733 25.2574 269.865 25.0256 269.137 24.5381C268.791 24.2133 268.537 23.8018 268.403 23.3462C268.269 22.8906 268.259 22.4073 268.374 21.9464C268.402 21.4435 268.543 20.9534 268.787 20.5125C269.031 20.0716 269.37 19.6913 269.781 19.3997C270.555 18.946 271.444 18.7274 272.34 18.7706C273.231 18.7148 274.116 18.9572 274.855 19.4596C275.185 19.7755 275.427 20.173 275.556 20.6122C275.685 21.0513 275.696 21.5167 275.588 21.9614C275.57 22.4675 275.436 22.9627 275.197 23.4093C274.959 23.8558 274.621 24.2419 274.211 24.5381C273.426 25.0092 272.521 25.2385 271.607 25.1973ZM271.726 24.1785C271.968 24.1817 272.207 24.1359 272.43 24.0437C272.652 23.9311 272.835 23.7534 272.954 23.5344C273.191 23.0368 273.329 22.4972 273.358 21.9464C273.455 21.4518 273.455 20.943 273.358 20.4484C273.325 20.3342 273.269 20.2278 273.195 20.1352C273.12 20.0426 273.028 19.9658 272.924 19.9091C272.717 19.8148 272.492 19.7686 272.265 19.7742C272.024 19.771 271.785 19.8169 271.562 19.9091C271.337 20.0109 271.153 20.1849 271.038 20.4034C270.813 20.874 270.676 21.3817 270.634 21.9015C270.533 22.3957 270.533 22.9053 270.634 23.3996C270.664 23.5188 270.718 23.6307 270.793 23.7285C270.867 23.8264 270.961 23.9081 271.068 23.9688C271.272 24.0914 271.503 24.1633 271.741 24.1785H271.726Z"
            fill="#F2F2F2"
          />
          <path
            d="M252.134 20.5234L250.054 20.6582C250.048 20.5211 250.008 20.3876 249.937 20.2698C249.867 20.1521 249.768 20.0539 249.65 19.9841C249.341 19.8226 248.995 19.7452 248.647 19.7594C248.246 19.7507 247.85 19.8434 247.494 20.029C247.347 20.1052 247.218 20.2132 247.117 20.3454C247.016 20.4775 246.945 20.6304 246.911 20.7931C246.878 20.9292 246.889 21.0723 246.943 21.2016C246.997 21.3309 247.09 21.4396 247.21 21.5121C247.508 21.705 247.858 21.7994 248.213 21.7818C248.544 21.7764 248.874 21.7463 249.201 21.6919L248.796 22.6656C248.331 22.6376 247.866 22.7144 247.434 22.8903C247.297 22.9339 247.172 23.0117 247.073 23.1165C246.973 23.2213 246.902 23.3497 246.866 23.4896C246.844 23.589 246.856 23.6927 246.9 23.7847C246.943 23.8767 247.015 23.9519 247.105 23.9989C247.367 24.1404 247.662 24.2077 247.958 24.1936C248.296 24.2007 248.631 24.1235 248.931 23.9689C249.201 23.8392 249.414 23.6155 249.53 23.3397L251.461 23.5345C251.272 23.8929 251.006 24.205 250.682 24.4483C250.304 24.7144 249.877 24.9027 249.425 25.0026C248.865 25.1316 248.293 25.1969 247.719 25.1974C246.87 25.2506 246.025 25.047 245.294 24.6131C244.83 24.2985 244.636 23.9539 244.71 23.5644C244.785 23.1749 245.429 22.5608 246.551 22.2312C246.038 22.1929 245.545 22.0113 245.129 21.7069C244.978 21.6024 244.861 21.4559 244.792 21.2856C244.723 21.1153 244.705 20.9285 244.74 20.7481C244.837 20.4256 245.008 20.1303 245.239 19.8858C245.471 19.6412 245.756 19.4543 246.072 19.3399C246.931 18.9457 247.867 18.7511 248.811 18.7707C249.637 18.7359 250.458 18.9004 251.206 19.2501C251.464 19.355 251.687 19.5298 251.851 19.7547C252.015 19.9796 252.113 20.2458 252.134 20.5234Z"
            fill="#F2F2F2"
          />
          <path
            d="M235.82 25.1975C234.969 25.2632 234.123 25.0301 233.425 24.5383C233.106 24.1978 232.889 23.774 232.799 23.3157C232.709 22.8573 232.749 22.3829 232.916 21.9466C232.999 21.431 233.188 20.9382 233.472 20.4997C233.755 20.0613 234.127 19.6867 234.563 19.3999C235.367 18.9523 236.278 18.7347 237.197 18.7708C238.066 18.7068 238.929 18.9507 239.637 19.4599C240.205 19.9243 240.37 20.7632 240.116 21.9616C240.043 22.4771 239.863 22.9715 239.587 23.4128C239.311 23.8541 238.945 24.2325 238.514 24.5233C237.698 24.9982 236.764 25.2319 235.82 25.1975ZM236.044 24.1787C236.29 24.1804 236.534 24.1346 236.763 24.0439C236.999 23.9283 237.2 23.7527 237.347 23.5346C237.62 23.0407 237.808 22.5037 237.9 21.9466C238.037 21.4598 238.078 20.951 238.02 20.4486C238 20.3372 237.956 20.2314 237.891 20.1384C237.827 20.0454 237.743 19.9673 237.646 19.9093C237.44 19.815 237.214 19.7688 236.987 19.7744C236.742 19.7728 236.498 19.8186 236.269 19.9093C236.034 20.0128 235.836 20.1852 235.7 20.4036C235.426 20.8665 235.233 21.3733 235.131 21.9017C235.003 22.3899 234.957 22.8964 234.997 23.3998C235.019 23.5162 235.066 23.6268 235.132 23.7247C235.199 23.8226 235.286 23.9058 235.386 23.969C235.591 24.0917 235.821 24.1635 236.059 24.1787H236.044Z"
            fill="#F2F2F2"
          />
          <path
            d="M209.568 20.029L209.882 18.9204H217.276C217.038 19.3967 216.683 19.8041 216.243 20.1038C215.359 20.6901 214.422 21.1917 213.444 21.6019C212.74 21.9023 212.055 22.2476 211.394 22.6356C211.095 22.8026 210.863 23.0669 210.735 23.3846C210.69 23.4951 210.687 23.6181 210.725 23.731C210.764 23.8438 210.842 23.939 210.945 23.9988C211.249 24.1733 211.598 24.2516 211.948 24.2235C212.334 24.2365 212.718 24.1596 213.07 23.9988C213.382 23.8372 213.622 23.5647 213.744 23.2348L215.839 23.3547C215.691 23.6998 215.474 24.0109 215.201 24.2685C214.928 24.5262 214.605 24.7251 214.253 24.8527C213.405 25.1669 212.507 25.3193 211.603 25.3021C210.754 25.3564 209.906 25.1805 209.149 24.7928C208.914 24.6873 208.725 24.5001 208.618 24.2659C208.51 24.0317 208.492 23.7664 208.565 23.5194C208.666 23.2122 208.841 22.9344 209.074 22.7105C209.401 22.4005 209.763 22.1291 210.152 21.9015C210.466 21.7218 211.005 21.4521 211.783 21.1076L213.28 20.4185L213.804 20.0889L209.568 20.029Z"
            fill="#F2F2F2"
          />
          <path
            d="M199.973 25.1977C199.145 25.2736 198.318 25.0386 197.654 24.5385C197.085 24.0142 196.995 23.1453 197.384 21.9468C197.679 20.8863 198.363 19.9765 199.3 19.4001C200.126 18.9546 201.056 18.7375 201.994 18.771C202.84 18.6994 203.683 18.945 204.359 19.46C204.883 19.9244 204.958 20.7634 204.598 21.9618C204.331 23.0346 203.654 23.9597 202.712 24.5385C201.876 25.0016 200.929 25.2293 199.973 25.1977ZM200.303 24.1789C200.553 24.1792 200.802 24.1335 201.036 24.0441C201.282 23.9248 201.497 23.7506 201.665 23.5348C201.987 23.0488 202.225 22.512 202.368 21.9468C202.553 21.4692 202.644 20.9608 202.638 20.4488C202.63 20.3407 202.598 20.2359 202.543 20.1423C202.489 20.0488 202.414 19.9691 202.323 19.9095C202.122 19.8143 201.902 19.7681 201.68 19.7746C201.429 19.7743 201.18 19.8201 200.946 19.9095C200.701 20.0165 200.489 20.1872 200.333 20.4038C200.01 20.8587 199.767 21.3653 199.614 21.9019C199.424 22.3782 199.327 22.8869 199.33 23.4C199.337 23.5121 199.369 23.6214 199.423 23.7198C199.478 23.8182 199.553 23.9033 199.644 23.9692C199.844 24.0896 200.07 24.1614 200.303 24.1789Z"
            fill="#F2F2F2"
          />
          <path
            d="M174.454 18.8755H176.565L174.814 23.3697C175.809 22.9364 176.855 22.6294 177.927 22.4558L177.508 23.5494C176.811 23.681 176.129 23.8817 175.472 24.1487C174.824 24.3627 174.228 24.7096 173.721 25.1673H171.985L174.454 18.8755Z"
            fill="#F2F2F2"
          />
          <path
            d="M164.097 25.1977C163.282 25.2817 162.466 25.0453 161.822 24.5385C161.313 24.0142 161.313 23.1453 161.822 21.9469C162.225 20.8742 162.987 19.9743 163.977 19.4001C164.828 18.9632 165.775 18.7469 166.731 18.771C167.565 18.6914 168.396 18.9384 169.051 19.4601C169.515 19.9245 169.515 20.7634 169.051 21.9619C168.677 23.0322 167.941 23.9382 166.971 24.5235C166.089 24.9974 165.098 25.23 164.097 25.1977ZM164.516 24.179C164.772 24.1791 165.025 24.1334 165.265 24.0441C165.524 23.9266 165.754 23.7526 165.938 23.5348C166.308 23.0536 166.601 22.5179 166.806 21.9469C167.037 21.4775 167.179 20.9697 167.225 20.4488C167.232 20.3431 167.211 20.2375 167.163 20.1428C167.116 20.048 167.044 19.9676 166.956 19.9095C166.761 19.8127 166.545 19.7663 166.327 19.7747C166.077 19.7744 165.828 19.8201 165.594 19.9095C165.332 20.0159 165.1 20.1858 164.92 20.4039C164.55 20.8516 164.257 21.3579 164.052 21.9019C163.81 22.3675 163.662 22.8767 163.618 23.4C163.613 23.5082 163.633 23.6162 163.677 23.7151C163.722 23.814 163.788 23.9011 163.873 23.9693C164.066 24.0935 164.287 24.1657 164.516 24.179Z"
            fill="#F2F2F2"
          />
          <path
            d="M377.38 130.676L374.312 128.654L374.85 132.714L377.38 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M423.554 130.676L420.306 128.654L421.234 132.714L423.554 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M469.728 130.676L466.271 128.654L467.603 132.714L469.728 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M516.007 130.676L512.354 128.654L514.076 132.714L516.007 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M248.782 130.676L251.85 128.654L251.311 132.714L248.782 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M203.326 130.676L206.589 128.654L205.661 132.714L203.326 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M156.778 130.676L160.236 128.654L158.904 132.714L156.778 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M113.942 130.676L117.579 128.654L115.873 132.714L113.942 130.676Z"
            fill="#F2F2F2"
          />
          <path
            d="M123.177 135.994H120.363L123.536 128.249C122.208 128.937 120.793 129.441 119.33 129.747L120.093 127.919C121.038 127.686 121.956 127.354 122.832 126.931C123.746 126.543 124.571 125.972 125.257 125.253H127.472L123.177 135.994Z"
            fill="#F2F2F2"
          />
          <path
            d="M137.725 125.283C138.252 125.221 138.786 125.289 139.281 125.479C139.777 125.669 140.218 125.977 140.568 126.377C141.182 127.23 141.107 128.669 140.374 130.676C139.841 132.408 138.797 133.938 137.38 135.065C136.281 135.822 134.972 136.214 133.639 136.189C133.076 136.255 132.507 136.177 131.983 135.962C131.46 135.747 130.999 135.402 130.645 134.96C130.091 134.152 130.226 132.713 131.019 130.661C131.577 128.967 132.63 127.479 134.043 126.392C135.124 125.646 136.412 125.259 137.725 125.283ZM137.096 126.946C136.753 126.952 136.416 127.034 136.108 127.186C135.736 127.387 135.418 127.674 135.18 128.024C134.639 128.851 134.201 129.742 133.878 130.676C133.517 131.502 133.27 132.374 133.145 133.268C133.116 133.436 133.127 133.609 133.176 133.773C133.226 133.937 133.312 134.087 133.429 134.211C133.663 134.381 133.948 134.465 134.237 134.451C134.586 134.452 134.93 134.37 135.24 134.211C135.614 134 135.936 133.709 136.183 133.358C136.727 132.523 137.159 131.622 137.47 130.676C137.827 129.861 138.064 128.998 138.174 128.114C138.201 127.948 138.19 127.778 138.14 127.617C138.091 127.455 138.005 127.308 137.889 127.186C137.642 127.007 137.34 126.922 137.036 126.946H137.096Z"
            fill="#F2F2F2"
          />
          <path
            d="M170.653 134.061L170.069 135.994H160.235C160.63 135.195 161.185 134.486 161.867 133.912C163.104 132.935 164.426 132.073 165.818 131.335C166.797 130.808 167.746 130.228 168.662 129.597C169.091 129.284 169.419 128.852 169.605 128.354C169.686 128.185 169.714 127.996 169.685 127.811C169.656 127.626 169.57 127.455 169.44 127.32C169.083 127.041 168.634 126.907 168.183 126.946C167.658 126.935 167.14 127.07 166.686 127.335C166.23 127.637 165.883 128.078 165.698 128.594L163.019 128.399C163.483 127.354 164.299 126.505 165.324 126.002C166.404 125.508 167.58 125.263 168.767 125.283C169.857 125.187 170.945 125.484 171.835 126.122C172.139 126.363 172.359 126.694 172.464 127.068C172.569 127.442 172.553 127.84 172.419 128.204C172.262 128.707 172.002 129.171 171.655 129.567C171.213 130.081 170.711 130.538 170.159 130.931C169.445 131.431 168.7 131.887 167.929 132.294C166.866 132.893 166.177 133.297 165.893 133.492C165.626 133.665 165.371 133.855 165.13 134.061H170.653Z"
            fill="#F2F2F2"
          />
          <path
            d="M183.525 125.283C184.074 125.224 184.63 125.291 185.149 125.481C185.668 125.671 186.136 125.977 186.518 126.377C187.222 127.231 187.296 128.669 186.743 130.676C186.388 132.392 185.465 133.939 184.123 135.065C183.079 135.831 181.81 136.226 180.516 136.189C179.94 136.24 179.36 136.156 178.822 135.942C178.284 135.729 177.803 135.393 177.418 134.961C176.789 134.152 176.774 132.713 177.418 130.661C177.795 128.971 178.728 127.457 180.067 126.362C181.075 125.642 182.287 125.265 183.525 125.283ZM183.061 126.946C182.727 126.949 182.399 127.031 182.103 127.186C181.748 127.383 181.454 127.673 181.25 128.024C180.795 128.861 180.448 129.752 180.217 130.676C179.926 131.51 179.765 132.384 179.738 133.268C179.729 133.443 179.757 133.618 179.822 133.781C179.886 133.944 179.985 134.091 180.112 134.212C180.358 134.381 180.652 134.465 180.95 134.451C181.289 134.454 181.624 134.371 181.923 134.212C182.278 134.007 182.572 133.713 182.776 133.358C183.239 132.514 183.587 131.612 183.809 130.676C184.088 129.85 184.239 128.986 184.258 128.114C184.264 127.941 184.233 127.767 184.166 127.607C184.098 127.447 183.997 127.303 183.869 127.186C183.632 127.022 183.349 126.938 183.061 126.946Z"
            fill="#F2F2F2"
          />
          <path
            d="M207.801 133.118L210.555 132.878C210.529 133.094 210.554 133.312 210.629 133.516C210.705 133.719 210.829 133.901 210.99 134.047C211.357 134.331 211.813 134.474 212.277 134.451C212.812 134.444 213.333 134.277 213.773 133.972C213.998 133.821 214.191 133.627 214.34 133.4C214.489 133.174 214.592 132.92 214.642 132.654C214.706 132.441 214.712 132.215 214.659 131.999C214.607 131.782 214.497 131.584 214.342 131.425C213.994 131.113 213.537 130.951 213.07 130.976C212.627 130.983 212.186 131.033 211.753 131.126L212.426 129.463C213.042 129.505 213.657 129.381 214.207 129.103C214.408 129.007 214.585 128.866 214.725 128.693C214.865 128.519 214.964 128.317 215.016 128.1C215.058 127.95 215.057 127.791 215.012 127.643C214.967 127.494 214.88 127.361 214.761 127.261C214.454 127.024 214.071 126.907 213.684 126.931C213.229 126.928 212.783 127.052 212.396 127.291C212.014 127.54 211.724 127.908 211.573 128.339L209.089 128.025C209.371 127.459 209.757 126.951 210.226 126.527C210.724 126.116 211.299 125.81 211.917 125.628C212.63 125.407 213.372 125.296 214.118 125.298C215.198 125.228 216.266 125.565 217.111 126.242C217.371 126.445 217.569 126.717 217.683 127.026C217.797 127.336 217.822 127.672 217.755 127.995C217.575 128.924 216.722 129.658 215.225 130.227C215.908 130.292 216.545 130.601 217.021 131.096C217.218 131.319 217.359 131.587 217.432 131.876C217.505 132.166 217.508 132.468 217.44 132.759C217.307 133.272 217.069 133.752 216.742 134.17C216.415 134.587 216.006 134.933 215.54 135.185C214.424 135.872 213.137 136.225 211.828 136.204C210.783 136.243 209.753 135.955 208.879 135.38C208.513 135.135 208.22 134.795 208.03 134.397C207.841 133.999 207.762 133.557 207.801 133.118Z"
            fill="#F2F2F2"
          />
          <path
            d="M229.294 125.283C229.842 125.245 230.392 125.322 230.908 125.51C231.423 125.699 231.894 125.994 232.288 126.377C232.716 126.997 232.998 127.706 233.112 128.45C233.226 129.195 233.169 129.956 232.946 130.676C232.778 132.357 232 133.918 230.761 135.065C229.761 135.838 228.522 136.236 227.259 136.189C226.666 136.23 226.07 136.141 225.515 135.929C224.959 135.717 224.456 135.387 224.041 134.961C223.322 134.152 223.173 132.713 223.562 130.661C223.766 129.007 224.558 127.481 225.792 126.362C226.798 125.605 228.037 125.223 229.294 125.283ZM228.995 126.946C228.666 126.951 228.343 127.033 228.052 127.186C227.724 127.383 227.463 127.676 227.304 128.024C226.909 128.863 226.647 129.757 226.525 130.676C226.314 131.523 226.238 132.397 226.301 133.268C226.31 133.448 226.356 133.625 226.436 133.787C226.516 133.95 226.628 134.094 226.765 134.211C227.023 134.379 227.326 134.462 227.633 134.451C227.963 134.454 228.287 134.371 228.576 134.211C228.91 134.009 229.175 133.712 229.339 133.358C229.735 132.51 229.993 131.605 230.103 130.676C230.304 129.838 230.374 128.974 230.312 128.114C230.299 127.935 230.25 127.761 230.167 127.601C230.085 127.441 229.971 127.3 229.833 127.186C229.584 127.024 229.292 126.941 228.995 126.946Z"
            fill="#F2F2F2"
          />
          <path
            d="M259.214 135.994L259.453 133.807H253.466L253.706 132.009L260.755 125.283H263.06L262.327 131.994H264.123L263.943 133.807H262.162L261.923 135.994H259.214ZM259.663 131.994L260.082 128.354L256.34 131.994H259.663Z"
            fill="#F2F2F2"
          />
          <path
            d="M275.139 125.283C275.711 125.247 276.286 125.325 276.828 125.512C277.37 125.7 277.869 125.994 278.297 126.377C278.776 126.973 279.12 127.666 279.306 128.408C279.492 129.15 279.516 129.924 279.375 130.676C279.388 132.312 278.759 133.888 277.623 135.065C276.672 135.853 275.459 136.254 274.226 136.189C273.618 136.22 273.01 136.128 272.438 135.917C271.867 135.705 271.345 135.38 270.903 134.961C270.452 134.351 270.137 133.652 269.979 132.91C269.822 132.169 269.825 131.401 269.99 130.661C270.002 129.065 270.629 127.536 271.741 126.392C272.696 125.611 273.908 125.215 275.139 125.283ZM274.989 126.946C274.674 126.947 274.365 127.03 274.091 127.186C273.774 127.379 273.531 127.675 273.403 128.025C273.098 128.877 272.926 129.771 272.894 130.676C272.763 131.535 272.763 132.409 272.894 133.268C272.957 133.643 273.16 133.981 273.463 134.212C273.725 134.382 274.034 134.466 274.346 134.451C274.695 134.469 275.041 134.372 275.33 134.175C275.62 133.977 275.836 133.69 275.947 133.358C276.252 132.495 276.424 131.591 276.456 130.676C276.584 129.827 276.584 128.964 276.456 128.114C276.39 127.744 276.187 127.413 275.887 127.186C275.618 127.019 275.306 126.935 274.989 126.946Z"
            fill="#F2F2F2"
          />
          <path
            d="M300.823 133.208L303.592 132.998C303.622 133.221 303.699 133.435 303.818 133.626C303.937 133.817 304.094 133.981 304.28 134.107C304.676 134.379 305.147 134.52 305.627 134.511C306.174 134.527 306.707 134.341 307.124 133.987C307.338 133.784 307.505 133.537 307.614 133.263C307.723 132.988 307.77 132.693 307.753 132.399C307.782 132.121 307.744 131.84 307.642 131.579C307.541 131.318 307.379 131.086 307.169 130.901C306.742 130.569 306.212 130.4 305.672 130.421C304.884 130.417 304.12 130.693 303.517 131.2L301.272 130.961L302.768 125.463H310.028V127.335H304.834L304.37 129.133C304.973 128.908 305.612 128.791 306.256 128.789C307.376 128.755 308.473 129.104 309.369 129.777C309.782 130.072 310.116 130.464 310.34 130.918C310.565 131.373 310.673 131.877 310.656 132.384C310.652 132.831 310.555 133.271 310.372 133.679C310.189 134.086 309.924 134.451 309.594 134.751C309.071 135.26 308.449 135.656 307.767 135.914C307.084 136.172 306.356 136.286 305.627 136.249C304.48 136.3 303.342 136.019 302.349 135.44C301.938 135.214 301.585 134.894 301.32 134.506C301.055 134.118 300.885 133.674 300.823 133.208Z"
            fill="#F2F2F2"
          />
          <path
            d="M320.969 125.283C322.159 125.224 323.329 125.614 324.246 126.377C324.774 126.954 325.179 127.633 325.437 128.372C325.694 129.112 325.798 129.895 325.743 130.676C325.845 131.459 325.78 132.255 325.553 133.012C325.326 133.768 324.942 134.468 324.426 135.066C323.981 135.461 323.462 135.764 322.9 135.956C322.337 136.149 321.742 136.228 321.148 136.189C319.881 136.241 318.642 135.801 317.691 134.961C317.191 134.371 316.815 133.686 316.586 132.947C316.357 132.208 316.28 131.431 316.359 130.661C316.278 129.888 316.358 129.107 316.595 128.367C316.832 127.626 317.221 126.944 317.736 126.362C318.63 125.591 319.791 125.204 320.969 125.283ZM320.969 126.946C320.654 126.947 320.344 127.03 320.071 127.186C319.784 127.388 319.577 127.685 319.487 128.025C319.259 128.889 319.173 129.784 319.232 130.676C319.196 131.549 319.292 132.423 319.517 133.268C319.626 133.647 319.858 133.979 320.175 134.212C320.451 134.369 320.763 134.452 321.081 134.452C321.398 134.452 321.711 134.369 321.986 134.212C322.282 134.008 322.494 133.705 322.585 133.358C322.799 132.481 322.88 131.577 322.825 130.676C322.847 129.811 322.741 128.948 322.51 128.114C322.403 127.739 322.171 127.411 321.852 127.186C321.593 127.033 321.299 126.951 320.999 126.946H320.969Z"
            fill="#F2F2F2"
          />
          <path
            d="M353.178 135.994L352.999 133.807H347.012L346.892 132.009L352.61 125.283H354.915L355.513 131.994H357.309L357.474 133.807H355.678L355.872 135.994H353.178ZM352.849 131.994L352.55 128.354L349.556 131.994H352.849Z"
            fill="#F2F2F2"
          />
          <path
            d="M366.888 125.283C368.11 125.233 369.309 125.621 370.27 126.377C371.437 127.512 372.128 129.049 372.201 130.676C372.386 131.428 372.402 132.211 372.25 132.97C372.097 133.728 371.779 134.444 371.318 135.066C370.898 135.466 370.398 135.773 369.852 135.967C369.305 136.16 368.723 136.236 368.145 136.189C366.843 136.227 365.572 135.791 364.568 134.961C363.457 133.804 362.83 132.266 362.817 130.661C362.65 129.918 362.65 129.148 362.815 128.404C362.981 127.661 363.308 126.964 363.775 126.362C364.192 125.978 364.684 125.684 365.219 125.498C365.755 125.313 366.323 125.24 366.888 125.283ZM367.082 126.946C366.777 126.945 366.476 127.028 366.214 127.186C365.944 127.39 365.762 127.69 365.705 128.025C365.559 128.902 365.559 129.798 365.705 130.676C365.756 131.562 365.938 132.435 366.244 133.268C366.392 133.656 366.659 133.987 367.008 134.212C367.289 134.375 367.61 134.458 367.936 134.451C368.243 134.463 368.546 134.379 368.804 134.212C369.08 134.004 369.268 133.699 369.328 133.358C369.464 132.469 369.464 131.565 369.328 130.676C369.263 129.799 369.071 128.936 368.759 128.115C368.607 127.738 368.346 127.414 368.01 127.186C367.727 127.027 367.407 126.945 367.082 126.946Z"
            fill="#F2F2F2"
          />
          <path
            d="M394.098 133.118L396.748 132.878C396.905 133.359 397.222 133.771 397.646 134.047C398.094 134.326 398.614 134.467 399.142 134.451C399.641 134.475 400.13 134.303 400.504 133.972C400.671 133.801 400.79 133.59 400.851 133.358C400.911 133.127 400.91 132.884 400.849 132.654C400.807 132.406 400.715 132.169 400.579 131.957C400.442 131.746 400.264 131.565 400.055 131.425C399.619 131.116 399.093 130.958 398.559 130.976C398.14 130.981 397.724 131.031 397.316 131.126V129.463C397.889 129.522 398.466 129.396 398.963 129.103C399.13 129.003 399.26 128.85 399.333 128.669C399.406 128.488 399.417 128.288 399.367 128.1C399.337 127.925 399.27 127.76 399.169 127.615C399.068 127.47 398.936 127.349 398.783 127.261C398.425 127.036 398.008 126.922 397.586 126.931C397.176 126.91 396.772 127.038 396.448 127.291C396.299 127.418 396.182 127.581 396.109 127.763C396.036 127.946 396.009 128.144 396.029 128.339L393.425 128.025C393.47 127.487 393.656 126.97 393.964 126.527C394.31 126.103 394.773 125.791 395.296 125.628C395.96 125.401 396.659 125.29 397.361 125.298C398.568 125.261 399.757 125.59 400.774 126.242C401.104 126.429 401.394 126.68 401.625 126.981C401.857 127.282 402.025 127.627 402.121 127.995C402.285 128.924 401.747 129.658 400.459 130.227C401.242 130.322 401.985 130.622 402.615 131.096C403.162 131.5 403.54 132.092 403.677 132.759C403.769 133.211 403.728 133.68 403.561 134.11C403.394 134.54 403.107 134.914 402.734 135.185C401.788 135.907 400.615 136.268 399.427 136.204C398.221 136.246 397.027 135.961 395.969 135.38C395.076 134.898 394.405 134.087 394.098 133.118Z"
            fill="#F2F2F2"
          />
          <path
            d="M412.523 125.283C413.775 125.247 415.003 125.632 416.011 126.377C417.276 127.49 418.113 129.011 418.375 130.676C418.637 131.395 418.732 132.164 418.651 132.925C418.571 133.685 418.318 134.418 417.911 135.066C417.526 135.462 417.057 135.768 416.54 135.962C416.023 136.156 415.469 136.234 414.918 136.189C413.581 136.217 412.276 135.783 411.221 134.961C410.013 133.827 409.239 132.306 409.036 130.661C408.782 129.964 408.686 129.219 408.756 128.481C408.826 127.742 409.06 127.029 409.44 126.392C409.839 125.99 410.323 125.681 410.857 125.49C411.39 125.298 411.959 125.227 412.523 125.283ZM412.882 126.946C412.58 126.938 412.283 127.022 412.029 127.186C411.905 127.288 411.803 127.416 411.731 127.561C411.658 127.705 411.617 127.863 411.61 128.025C411.551 128.917 411.642 129.814 411.88 130.676C412.023 131.575 412.295 132.448 412.688 133.268C412.874 133.654 413.164 133.981 413.526 134.212C413.819 134.373 414.149 134.456 414.484 134.451C414.786 134.46 415.083 134.376 415.337 134.212C415.463 134.106 415.566 133.976 415.641 133.829C415.715 133.682 415.76 133.522 415.771 133.358C415.807 132.454 415.706 131.55 415.472 130.676C415.324 129.787 415.047 128.923 414.649 128.115C414.463 127.732 414.172 127.41 413.81 127.186C413.525 127.031 413.207 126.949 412.882 126.946Z"
            fill="#F2F2F2"
          />
          <path
            d="M450.525 134.062L451.079 135.994H441.26C441.163 135.276 441.299 134.546 441.649 133.912C442.317 132.931 443.136 132.061 444.074 131.335C444.732 130.816 445.339 130.234 445.885 129.597C446.008 129.418 446.091 129.214 446.127 128.999C446.163 128.784 446.152 128.564 446.094 128.354C445.975 127.919 445.691 127.548 445.301 127.32C444.848 127.056 444.329 126.926 443.804 126.946C443.351 126.91 442.901 127.05 442.547 127.335C442.401 127.506 442.302 127.712 442.26 127.933C442.218 128.153 442.234 128.381 442.308 128.594L439.509 128.399C439.4 127.957 439.425 127.492 439.583 127.065C439.74 126.638 440.022 126.267 440.392 126.002C441.3 125.479 442.339 125.23 443.385 125.283C444.626 125.245 445.854 125.535 446.948 126.122C447.8 126.552 448.45 127.3 448.759 128.204C448.877 128.651 448.877 129.121 448.759 129.567C448.619 130.068 448.374 130.532 448.04 130.931C447.613 131.431 447.137 131.888 446.618 132.294C445.915 132.893 445.466 133.298 445.286 133.492C445.126 133.666 444.986 133.857 444.867 134.062H450.525Z"
            fill="#F2F2F2"
          />
          <path
            d="M458.278 125.283C459.561 125.259 460.818 125.642 461.87 126.377C463.232 127.477 464.207 128.983 464.654 130.676C465.298 132.698 465.298 134.167 464.654 135.065C464.283 135.48 463.816 135.799 463.295 135.994C462.774 136.19 462.213 136.257 461.661 136.189C460.29 136.201 458.951 135.77 457.844 134.961C456.541 133.841 455.629 132.335 455.24 130.661C454.641 128.654 454.671 127.231 455.345 126.362C455.716 125.965 456.174 125.661 456.683 125.473C457.193 125.286 457.739 125.221 458.278 125.283ZM458.787 126.946C458.499 126.938 458.216 127.022 457.979 127.186C457.859 127.289 457.764 127.418 457.702 127.563C457.64 127.709 457.611 127.867 457.62 128.025C457.668 128.93 457.85 129.823 458.159 130.676C458.399 131.582 458.757 132.454 459.221 133.268C459.446 133.661 459.772 133.986 460.164 134.212C460.463 134.371 460.798 134.454 461.137 134.451C461.431 134.468 461.721 134.383 461.96 134.212C462.08 134.106 462.173 133.973 462.233 133.825C462.292 133.677 462.317 133.517 462.305 133.358C462.263 132.44 462.076 131.535 461.751 130.676C461.508 129.78 461.151 128.919 460.688 128.114C460.459 127.729 460.134 127.409 459.745 127.186C459.463 127.038 459.151 126.956 458.832 126.946H458.787Z"
            fill="#F2F2F2"
          />
          <path
            d="M498.225 135.994H495.412L492.418 128.249C491.556 128.984 490.529 129.498 489.425 129.747L488.766 127.89C489.479 127.683 490.149 127.348 490.742 126.901C491.034 126.718 491.282 126.473 491.468 126.183C491.655 125.893 491.775 125.565 491.819 125.223H494.05L498.225 135.994Z"
            fill="#F2F2F2"
          />
          <path
            d="M504.063 125.283C505.376 125.271 506.664 125.651 507.76 126.377C509.228 127.462 510.342 128.958 510.963 130.676C511.816 132.699 511.965 134.167 511.382 135.066C511.043 135.481 510.604 135.802 510.106 135.999C509.608 136.196 509.068 136.261 508.538 136.189C507.131 136.19 505.758 135.761 504.601 134.961C503.204 133.853 502.163 132.357 501.608 130.661C500.8 128.654 500.695 127.231 501.279 126.362C501.619 125.967 502.051 125.662 502.536 125.474C503.022 125.286 503.546 125.221 504.063 125.283ZM504.736 126.946C504.453 126.936 504.174 127.021 503.943 127.186C503.835 127.294 503.755 127.427 503.711 127.573C503.666 127.72 503.659 127.875 503.688 128.025C503.817 128.944 504.084 129.838 504.482 130.676C504.808 131.593 505.251 132.464 505.799 133.268C506.064 133.66 506.418 133.983 506.832 134.212C507.142 134.37 507.486 134.452 507.834 134.451C508.119 134.469 508.4 134.384 508.628 134.212C508.741 134.104 508.825 133.969 508.872 133.819C508.919 133.67 508.928 133.511 508.897 133.358C508.753 132.429 508.476 131.526 508.074 130.676C507.736 129.771 507.289 128.911 506.742 128.115C506.476 127.733 506.128 127.415 505.724 127.186C505.416 127.034 505.079 126.952 504.736 126.946Z"
            fill="#F2F2F2"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_166_7547"
            x="0"
            y="1"
            width="626"
            height="241"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="54" />
            <feGaussianBlur stdDeviation="7" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_166_7547"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_166_7547"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_166_7547">
            <rect
              width="603"
              height="174"
              fill="white"
              transform="translate(12)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SvgFootballField;
