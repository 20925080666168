import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import CmsImage from "../utilities/cms-image";

const ContentWithImage = ({ data }) => {
  return (
    <section id={convertId(data.name)} className="px-4 py-20">
      <div className="container mx-auto sz">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-16">
          <div className="lg:col-span-3">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
          <div>
            <CmsImage image={data.image} />
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ContentWithImage on WpPage_Sections_Sections_ContentWithImage {
    fieldGroupName
    name
    content
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default ContentWithImage;
