import React from "react";
import convertId from "../../utilities/convert-id";
import { graphql } from "gatsby";
import Content from "../utilities/content";
import Link from "../utilities/link";
import CmsImage from "../utilities/cms-image";

const HomeHero = ({ data }) => {
  return (
    <section
      id={convertId(data.name)}
      className="section home-banner section-home-hero"
    >
      {data.selectBackground === "video" && (
        <>
          {data.backgroundVideo && (
            <video autoPlay loop playsInline muted className="bg-video">
              <source
                src={data.backgroundVideo.mediaItemUrl}
                type="video/mp4"
              />
            </video>
          )}
          <div className="bg-overlay"></div>
        </>
      )}

      {data.selectBackground === "image" && (
        <CmsImage className="section-img" image={data.backgroundImage} />
      )}

      <div className="container sz">
        <div className="row">
          <div className="col-lg-7">
            <Content className="content-home-hero">{data.content}</Content>
            <div className="d-lg-inline-flex align-items-center home-banner-cta">
              <strong className="d-block d-lg-inline-block text-uppercase me-2">
                Not sure what you need?
              </strong>
              <div className="dropdown">
                <a
                  className="btn btn-link text-decoration-none dropdown-toggle"
                  href="/#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Find Products by Sport
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-higreen dropdown-menu-end"
                  aria-labelledby="dropdownMenuLink"
                >
                  {data.pageLists &&
                    data.pageLists.map((item) => (
                      <li key={item.id}>
                        <Link to={item.uri} className="dropdown-item">
                          {item.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment HomeHero on WpPage_Sections_Sections_HomeHero {
    fieldGroupName
    name
    content
    selectBackground
    pageLists {
      ... on WpPage {
        id
        uri
        slug
        title
        link
      }
    }
    backgroundVideo {
      altText
      mediaItemUrl
      localFile {
        extension
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    backgroundImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData
        }
        extension
        publicURL
      }
    }
  }
`;

export default HomeHero;
