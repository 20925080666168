import React, { useState } from "react";
import convertId from "../../utilities/convert-id";
import { graphql, Link } from "gatsby";
import Content from "../utilities/content";
import PatternColTitleContent from "../../images/pattern-column-title-and-content.svg";
import CmsImage from "../utilities/cms-image";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import FsLightbox from "fslightbox-react";

const ProductRootZoneShowcase = ({ data }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  const [forceUpdate, setForceUpdate] = useState(false);

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const productImages = data.images.map(
    (image) => image.image?.localFile.publicURL
  );

  return (
    <>
      <section
        id={convertId(data.name)}
        className={`section-product-root-zones-showcase section clipped-small-top bg-white ${
          data.hideShapeBackground ? "hide-shape-bg" : ""
        }`}
        style={{
          background: `url(${PatternColTitleContent}) no-repeat center center / cover`,
        }}
      >
        <div className="container mb-5">
          <div className="text-center">
            <Content className="showcase-title display-5 tk-rift text-uppercase text-primary">
              {data.title}
            </Content>
            <Content className="showcase-description lead text-secondary fw-bold">
              {data.description}
            </Content>
          </div>
        </div>
        {data.images && data.images.length > 0 && (
          <div className="swiper-container">
            <Swiper
              modules={[Navigation]}
              className="swiper-showcase"
              slidesPerView={1}
              spaceBetween={30}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              breakpoints={{
                768: { slidesPerView: 4 },
              }}
              loop
              loopAdditionalSlides={data.images.length - 1}
            >
              {data.images.map((slide, index) => {
                return (
                  <SwiperSlide key={slide.name}>
                    <Link
                      to={slide.link}
                      className="d-block product product-green"
                    >
                      {slide.image && (
                        <div
                          role="button"
                          className="ratio ratio-product-image"
                          onClick={() => openLightboxOnSlide(index + 1)}
                        >
                          <div>
                            <CmsImage
                              className="img-fluid w-100 img-clipped mx-auto d-block"
                              image={slide.image}
                            />
                          </div>
                        </div>
                      )}

                      {(slide.name || slide.location) && (
                        <span>
                          {slide.name && <strong>{slide.name}</strong>}
                          {slide.name && slide.location && " \\ "}
                          {slide.location && slide.location}
                        </span>
                      )}
                    </Link>
                  </SwiperSlide>
                );
              })}
              <div className="swiper-button-prev" />
              <div className="swiper-button-next" />
            </Swiper>
          </div>
        )}
      </section>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={productImages}
        slide={lightboxController.slide}
      />
    </>
  );
};

export const query = graphql`
  fragment ProductRootZoneShowcase on WpPage_Sections_Sections_ProductRootZoneShowcase {
    description
    fieldGroupName
    name
    title
    images {
      name
      location
      link
      image {
        altText
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(width: 493, height: 328)
          }
        }
      }
    }
    hideShapeBackground
  }
`;

export default ProductRootZoneShowcase;
