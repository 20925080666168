// Handle <head> meta and render our sections

import React from "react";
import { Helmet } from "react-helmet";
import RenderSections from "../utilities/render-sections";

const Builder = ({ title, page, sections, themeSettings, prefix }) => {
  return (
    <>
      <Helmet>
        <title>
          {page.metaTitle ? page.metaTitle : title} -{" "}
          {themeSettings.siteMetaTitle}
        </title>
        {page.metaDescription && (
          <meta name="description" content={page.metaDescription} />
        )}
        {page.pageCssOverrides && <style>{page.pageCssOverrides}</style>}
        {themeSettings.customCssOverrides && (
          <style>{themeSettings.customCssOverrides}</style>
        )}

        {/*<link
          href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        ></link>*/}
        <link
          rel="stylesheet"
          href="https://use.typekit.net/esz4xad.css"
        ></link>
      </Helmet>
      <RenderSections sections={sections} prefix={prefix} />
    </>
  );
};

export default Builder;
